/*
 * 업무구분: 서면 외화
 * 화 면 명: MSPPI550P
 * 화면설명: 서면 외화 적합성
 * 작 성 일: 0000.00.00
 * 작 성 자: 
 */
<template>
  <ur-page-container class="msp" title="서면 외화" ref="page" :show-title="false" type="subpage" :topButton="true">
    <ur-box-container direction="column" alignV="start" class="msp-pi-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow >
              <mo-button class="btn_popup_back" @click="fn_ClosePopup()"></mo-button>
              <div slot="nav" class="icon-wrapper" @click="fn_ClosePopup()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '서면 외화' : '서면 외화'}}</div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area mt20">
              <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20">
                <ur-box-container alignV="start" componentid="" direction="column" class="row-box align-item-center bd-b-Ty2 pt16 pb11">
                  <span class="w100px h100 mr20 fs16rem"><span>고객명</span></span>
                  <div @click.stop="fn_searchCustomerNm">
                  <!-- <mo-text-field  class="form-input-name flex-1 mb0" ref="refCustNmTextField" v-model="displayCustNm" :clearable="searchable && (displayCustNm !== '')" :searchable="searchable" underline placeholder="고객명을 입력해 주세요" @keyup.enter="fn_searchCustomerNm" @click-icon="fn_searchCustomerNm" :disabled="isDisableCust"/> -->
                    <mo-text-field  class="form-input-name flex-1 mb0" ref="refCustNmTextField" v-model="displayCustNm" :searchable="searchable" underline placeholder="고객명을 입력해 주세요" disabled/>
                  </div>
                </ur-box-container>
                <ur-box-container alignV="start" componentid="" direction="column" class="row-box align-item-center bd-b-Ty2 pt16 pb11">
                  <span class="w100px h100 fs16rem"><span>주민등록번호</span></span>
                  <span class="flex-1 fs16rem fwb ml20">{{custInfo.lbContrRrn}}</span>
                </ur-box-container>
              </ur-box-container>
            </ur-box-container>   

            <ur-box-container alignV="start" componentid="" direction="column" class="question-area">
              <div class="question_list"> 
                <!-- question result -->
                <div class="list-item" :class="{'on':card0.detailAreaOpen}" @click="card0.detailAreaOpen=!card0.detailAreaOpen" v-if="card0.result"><!-- // 포커싱: on -->         
                  <div class="list-item__contents">
                    <ur-box-container alignV="start" componentid="" direction="column" class="info-box" >
                      <div class="q-title">적합성 진단결과</div>
                      <div class="q-content full mt10">
                        <ur-box-container alignV="start" componentid="" direction="column" class="row-box fexTy3 bd-T-Ty1 bd-b-Ty2 pt16 pb12">
                          <span class="">보험상품 적합성</span>
                          <span class="w120px fex-clumn txt-right">
                            <b class="crTy-orange2">{{lbInsrAnlyRslt}}</b>
                            <span class="fs14rem crTy-bk7">{{lbInsrAnlyRslt_Date}}</span> 
                          </span>
                        </ur-box-container>
                        <ur-box-container alignV="start" componentid="" direction="column" class="row-box fexTy3 bd-b-Ty2 pt16 pb12">
                          <span class="">특별계정펀드 적합성</span>
                          <span class="w160px fex-clumn txt-right">
                            <b class="crTy-purple1">{{lbRskEftAnlyRslt}}</b>
                            <span class="fs14rem crTy-bk7">{{lbRskEftAnlyRslt_Date}}</span>
                          </span>
                        </ur-box-container>
                        <ur-box-container alignV="start" componentid="" direction="column" class="row-box fexTy3 bd-b-Ty2 pt16 pb12">
                          <span class="">승인상태</span>
                          <span class="w120px fex-clumn txt-right">
                            <b class="crTy-orange2">{{aprvMsg}}</b>
                          </span>
                        </ur-box-container>
                      </div>  
                    </ur-box-container>
                  </div>
                </div><!-- // list-item -->
                <!-- // question result -->

                <!-- 0. 전문금융소비자여부 -->
                <div class="list-item" :class="{'on':card00.detailAreaOpen,'selected':card00.selected}" @click="card00.detailAreaOpen=!card1.detailAreaOpen"><!-- // 포커싱: on, 문항 답변시 selected class 추가 -->
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num mr4">0.</b><span class="">전문금융소비자여부</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">전문금융소비자여부</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item">
                          <mo-radio class="radio" v-model="c00" value="1" @input="fn_SetDataQ01(1)" :disabled="isDisableIns">일반금융소비자</mo-radio>
                        </div>
                        <div class="q-item">
                          <mo-radio class="radio" v-model="c00" value="2" @input="fn_SetDataQ01(2)" :disabled="isDisableIns">전문금융소비자</mo-radio>
                          <mo-button class="ns-btn-round white sm" :disabled='isDisableBtnExprt' @click.stop="fn_showTSSPI721P()">필수확인사항</mo-button>
                        </div>
                      </ur-box-container>
                    </div>
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card00.selectedValue}}</span></div>
                    </div>
                  </div>
                </div>
                <!-- 0. 금융취약계층 -->
                <div class="list-item" :class="{'on':card000.detailAreaOpen,'selected':card000.selected}" @click="fn_SetDataQ02Control()">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num mr4">0.</b><span class="">금융취약계층</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">금융취약계층</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice02" value="1" @input="fn_SetDataQ02(1)" :disabled="isDisableIns">해당없음</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice02" value="2" @input="fn_SetDataQ02(2)" :disabled="isDisableIns">해당(65세 이상)</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice02" value="3" @input="fn_SetDataQ02(3)" :disabled="isDisableIns">해당(미성년자)</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice02" value="4" @input="fn_SetDataQ02(4)" :disabled="isDisableIns">해당(정신적 장애 등)</mo-checkbox></div>
                      </ur-box-container>
                      <div class="text-blue-box mt20" v-if="!isDisableLeglAgnt">
                        <div class="fwm">미성년 정신적 장애 등의 경우, 법정대리인이 대신 적합성 진단을 하였습니까?</div>
                        <div class="ns-radio-list flex mt16">
                          <mo-radio class="bgwhite" v-model="c000" value="1" @input="fn_SetDataQ03(1)">예</mo-radio>
                          <mo-radio class="bgwhite" v-model="c000" value="2" @input="fn_SetDataQ03(2)">아니오</mo-radio>
                        </div>
                      </div>
                      <div class="mt20">
                        <ul class="terms-list-area crTy-bk7 fs14rem">
                          <li>취약금융소비자란 고령 투자자(만 65세 이상), 미성년자, 정신적 장애로 일상이나 사회생활에 제약을 받는 자 등으로 합리적 판단이 곤란한 자로서 계약자 보호의 필요성이 높은 자를 의미합니다.</li>
                        </ul>
                      </div>
                      <div class="ns-btn-relative-s">
                        <div class="relative-div mt20">
                          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" :disabled="subBtn000" @click.stop="fn_SetDataQ02Confirm()">확인</mo-button>
                        </div>
                      </div> 
                    </div> 
                    <div class="q-result">
                      <div v-if="card000.selectedValue1 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card000.selectedValue1}}</span></div>
                      <div v-if="card000.selectedValue2 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card000.selectedValue2}}</span></div>
                    </div>
                  </div>
                </div>
                <!-- 1. 가입목적 금융상품 -->
                <div class="list-item" :class="{'on':card1.detailAreaOpen,'selected':card1.selected}" @click="card1.detailAreaOpen=!card1.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">1.</b><span class="ml4">가입목적 금융상품</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">가입목적 금융상품</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c1" value="1" @input="fn_SetDataAssort(1,1)" :disabled="isDisableIns">예금·적금</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c1" value="2" @input="fn_SetDataAssort(1,2)" :disabled="isDisableIns">주식·채권 등 금융투자상품</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c1" value="3" @input="fn_SetDataAssort(1,3)" :disabled="isDisableIns">일반보험</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c1" value="4" @input="fn_SetDataAssort(1,4)" :disabled="isDisableIns">변액보험</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c1" value="5" @input="fn_SetDataAssort(1,5)" :disabled="isDisableIns">외화 일반보험(비변액)</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c1" value="6" @input="fn_SetDataAssort(1,6)" :disabled="isDisableIns">외화 변액보험</mo-radio></div>
                      </ur-box-container>
                    </div> 
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card1.selectedValue}}</span></div>
                    </div>
                  </div>
                </div>
                <!-- 2. 외화보험 가입목적 -->
                <div class="list-item" :class="{'on':card2.detailAreaOpen,'selected':card2.selected}" @click="card2.detailAreaOpen=!card2.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">2.</b><span class="ml4">외화보험 가입목적</span></div>
                      <div class="q-selected">
                        <mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">외화보험 가입목적</span>
                      </div>
                    </div>
                    <div class="q-content">                      
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c2" value="1" @input="fn_SetDataAssort(2,1)" :disabled="isDisableIns">환율 변동에 따른 재산 증식 등 단기적인 이익 취득 목적</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c2" value="2" @input="fn_SetDataAssort(2,2)" :disabled="isDisableIns">장기 저축을 통한 목돈 마련</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c2" value="3" @input="fn_SetDataAssort(2,3)" :disabled="isDisableIns">노후를 위한 연금자산 마련</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c2" value="4" @input="fn_SetDataAssort(2,4)" :disabled="isDisableIns">위험에 대비한 보장자산(외화) 마련</mo-radio></div>
                      </ur-box-container>
                    </div> 
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card2.selectedValue}}</span></div>
                    </div>
                  </div>
                </div>
                <!-- 3. 외화보험 가입 후 활용계획(복수선택 가능) -->
                <div class="list-item" :class="{'on':card3.detailAreaOpen,'selected':card3.selected}" @click="fn_SetDataQ3Control()">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">3.</b><span class="ml4">외화보험 가입 후 활용계획 <span class="fs-small">(복수선택 가능)</span></span> </div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">외화보험 가입 후 활용계획 <span class="fs-small">(복수선택 가능)</span></span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice3" value="1" @input="fn_SetDataQ3(1)" :disabled="isDisableIns">향후 해외 거주할 예정이며, 준비 또는 생활 자금 등으로 활용</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice3" value="2" @input="fn_SetDataQ3(2)" :disabled="isDisableIns">가끔 해외여행경비로 활용</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice3" value="3" @input="fn_SetDataQ3(3)" :disabled="isDisableIns">장기의 미래에 본인 생활자금 또는 유학계획이 있는 자녀 생활자금등으로 활용</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice3" value="4" @input="fn_SetDataQ3(4)" :disabled="isDisableIns">장기의 미래에 유족 등에게 외화자산 상속 등 보장자산으로 활용</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice3" value="5" @input="fn_SetDataQ3(5)" :disabled="isDisableIns">안정적 재산증식 수단으로 손실가능성이 없는 외화를 활용</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice3" value="6" @input="fn_SetDataQ3(6)" :disabled="isDisableIns">외화 보장자산 확보를 통한 보장자산 다각화 등 미래 위험분산에 활용</mo-checkbox></div>
                      </ur-box-container>
                      <div class="ns-btn-relative-s">
                        <div class="relative-div mt20">
                          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" :disabled="subBtn3" @click.stop="fn_SetDataQ3Confirm()">확인</mo-button>
                        </div>
                      </div>
                    </div>
                    <div class="q-result">
                      <div v-if="card3.selectedValue1 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card3.selectedValue1}}</span></div>
                      <div v-if="card3.selectedValue2 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card3.selectedValue2}}</span></div>
                      <div v-if="card3.selectedValue3 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card3.selectedValue3}}</span></div>
                      <div v-if="card3.selectedValue4 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card3.selectedValue4}}</span></div>
                      <div v-if="card3.selectedValue5 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card3.selectedValue5}}</span></div>
                      <div v-if="card3.selectedValue6 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card3.selectedValue6}}</span></div>
                    </div>  
                  </div>   
                </div>
                <!-- 4. 월 평균 소득 -->
                <div class="list-item" :class="{'on':card4.detailAreaOpen,'selected':card4.selected}" @click="card4.detailAreaOpen=!card4.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">4.</b><span class="ml4">월 평균 소득</span></div>
                      <div class="q-selected">
                        <mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">월 평균 소득</span>
                      </div>
                    </div>
                    <div class="q-content">                      
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c4" value="1" @input="fn_SetDataAssort(4,1)" :disabled="isDisableIns">200만원 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c4" value="2" @input="fn_SetDataAssort(4,2)" :disabled="isDisableIns">500만원 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c4" value="3" @input="fn_SetDataAssort(4,3)" :disabled="isDisableIns">1,000만원 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c4" value="4" @input="fn_SetDataAssort(4,4)" :disabled="isDisableIns">1,000만원 이상</mo-radio></div>
                      </ur-box-container>
                    </div> 
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card4.selectedValue}}</span></div>
                    </div>  
                  </div>   
                </div>
                <!-- 5. 월 평균 소득대비 보험료 비중 -->
                <div class="list-item" :class="{'on':card5.detailAreaOpen,'selected':card5.selected}" @click="card5.detailAreaOpen=!card5.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">5.</b><span class="ml4">월 평균 소득대비 보험료 비중</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">월 평균 소득대비 보험료 비중</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c5" value="1" @input="fn_SetDataAssort(5,1)" :disabled="isDisableIns">10% 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c5" value="2" @input="fn_SetDataAssort(5,2)" :disabled="isDisableIns">10~20%</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c5" value="3" @input="fn_SetDataAssort(5,3)" :disabled="isDisableIns">20~30%</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c5" value="4" @input="fn_SetDataAssort(5,4)" :disabled="isDisableIns">30~50%</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c5" value="5" @input="fn_SetDataAssort(5,5)" :disabled="isDisableIns">50% 이상</mo-radio></div>
                      </ur-box-container>
                    </div> 
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card5.selectedValue}}</span></div>
                    </div>
                  </div>
                </div>
                <!-- 6. 추가 납부 가능금액 -->
                <div class="list-item" :class="{'on':card6.detailAreaOpen,'selected':card6.selected}" @click="card6.detailAreaOpen=!card6.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">6.</b><span class="ml4">추가 납부 가능금액</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">추가 납부 가능금액</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c6" value="1" @input="fn_SetDataAssort(6,1)" :disabled="isDisableIns">10만원 이하</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c6" value="2" @input="fn_SetDataAssort(6,2)" :disabled="isDisableIns">10~30만원</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c6" value="3" @input="fn_SetDataAssort(6,3)" :disabled="isDisableIns">30~50만원</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c6" value="4" @input="fn_SetDataAssort(6,4)" :disabled="isDisableIns">50~100만원</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c6" value="5" @input="fn_SetDataAssort(6,5)" :disabled="isDisableIns">100만원 초과</mo-radio></div>
                      </ur-box-container>
                    </div>
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card6.selectedValue}}</span></div>
                    </div>  
                  </div>   
                </div>
                <!-- 7. 보험료 납입가능기간 -->
                <div class="list-item" :class="{'on':card7.detailAreaOpen,'selected':card7.selected}" @click="card7.detailAreaOpen=!card7.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">7.</b><span class="ml4">보험료 납입가능기간</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">보험료 납입가능기간</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c7" value="1" @input="fn_SetDataAssort(7,1)" :disabled="isDisableIns">3년 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c7" value="2" @input="fn_SetDataAssort(7,2)" :disabled="isDisableIns">3년 이상 7년 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c7" value="3" @input="fn_SetDataAssort(7,3)" :disabled="isDisableIns">7년 이상 10년 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c7" value="4" @input="fn_SetDataAssort(7,4)" :disabled="isDisableIns">10년 이상 20년 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c7" value="5" @input="fn_SetDataAssort(7,5)" :disabled="isDisableIns">20년 이상</mo-radio></div>
                      </ur-box-container>
                    </div>
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card7.selectedValue}}</span></div>
                    </div>
                  </div> 
                </div>
                <!-- 8. 예상 유지기간 -->
                <div class="list-item" :class="{'on':card8.detailAreaOpen,'selected':card8.selected}" @click="card8.detailAreaOpen=!card8.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">8.</b><span class="ml4">예상 유지기간</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">예상 유지기간</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c8" value="1" @input="fn_SetDataAssort(8,1)" :disabled="isDisableIns">5년 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c8" value="2" @input="fn_SetDataAssort(8,2)" :disabled="isDisableIns">5년 이상 7년 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c8" value="3" @input="fn_SetDataAssort(8,3)" :disabled="isDisableIns">7년 이상 10년 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c8" value="4" @input="fn_SetDataAssort(8,4)" :disabled="isDisableIns">10년 이상 20년 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c8" value="5" @input="fn_SetDataAssort(8,5)" :disabled="isDisableIns">20년 이상 30년 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c8" value="6" @input="fn_SetDataAssort(8,6)" :disabled="isDisableIns">30년 이상</mo-radio></div>
                      </ur-box-container>
                    </div> 
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card8.selectedValue}}</span></div>
                    </div>
                  </div>
                </div>
                <!-- 9. 중도해지 가능성 -->
                <div class="list-item" :class="{'on':card9.detailAreaOpen,'selected':card9.selected}" @click="card9.detailAreaOpen=!card9.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">9.</b><span class="ml4">중도해지 가능성</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">중도해지 가능성</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c9" value="1" @input="fn_SetDataAssort(9,1)" :disabled="isDisableIns">매우 낮음(30%미만)</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c9" value="2" @input="fn_SetDataAssort(9,2)" :disabled="isDisableIns">낮음(30% 이상)</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c9" value="3" @input="fn_SetDataAssort(9,3)" :disabled="isDisableIns">가능성 있음(50% 이상)</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c9" value="4" @input="fn_SetDataAssort(9,4)" :disabled="isDisableIns">높음(70% 이상)</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c9" value="5" @input="fn_SetDataAssort(9,5)" :disabled="isDisableIns">매우 높음(90% 이상)</mo-radio></div>
                      </ur-box-container>
                    </div> 
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card9.selectedValue}}</span></div>
                    </div>
                  </div>
                </div>
                <!-- 10. 중도해지 가능성 -->
                <div class="list-item" :class="{'on':card10.detailAreaOpen,'selected':card10.selected}" @click="fn_SetDataQ10Control()">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">10.</b><span class="ml4">외화금융경험상품 <span class="fs-small">(복수선택 가능)</span></span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">외화금융경험상품 <span class="fs-small">(복수선택 가능)</span></span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice10" value="1" @input="fn_SetDataQ10(1)" :disabled="isDisableIns">가입경험 없음</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice10" value="2" @input="fn_SetDataQ10(2)" :disabled="isDisableIns">외화예금</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice10" value="3" @input="fn_SetDataQ10(3)" :disabled="isDisableIns">외화펀드</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice10" value="4" @input="fn_SetDataQ10(4)" :disabled="isDisableIns">해외주식</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice10" value="5" @input="fn_SetDataQ10(5)" :disabled="isDisableIns">외화저축성보험</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice10" value="6" @input="fn_SetDataQ10(6)" :disabled="isDisableIns">외화보장성보험</mo-checkbox></div>
                        <div class="q-item"><mo-checkbox class="check" v-model="formInput.choice10" value="7" @input="fn_SetDataQ10(7)" :disabled="isDisableIns">기타</mo-checkbox></div>
                      </ur-box-container>
                      <div class="ns-btn-relative-s">
                        <div class="relative-div mt20">
                          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" :disabled="subBtn10" @click.stop="fn_SetDataQ10Confirm()">확인</mo-button>
                        </div>
                      </div>
                    </div> 
                    <div class="q-result">
                      <div v-if="card10.selectedValue1 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card10.selectedValue1}}</span></div>
                      <div v-if="card10.selectedValue2 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card10.selectedValue2}}</span></div>
                      <div v-if="card10.selectedValue3 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card10.selectedValue3}}</span></div>
                      <div v-if="card10.selectedValue4 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card10.selectedValue4}}</span></div>
                      <div v-if="card10.selectedValue5 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card10.selectedValue5}}</span></div>
                      <div v-if="card10.selectedValue6 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card10.selectedValue6}}</span></div>
                      <div v-if="card10.selectedValue7 !== ''" class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card10.selectedValue7}}</span></div>
                    </div>  
                  </div>   
                </div>
                <!-- 11. 외화금융상품 경험 가입기간 -->
                <div class="list-item" :class="{'on':card11.detailAreaOpen,'selected':card11.selected}" @click="card11.detailAreaOpen=!card11.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">11.</b><span class="ml4">외화금융상품 경험 가입기간</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">외화금융상품 경험 가입기간</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c11" value="1" @input="fn_SetDataQ11(1)" :disabled="isDisableIns||isDisableInexpr">가입경험 없음</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c11" value="2" @input="fn_SetDataQ11(2)" :disabled="isDisableIns||isDisableInexpr">6개월 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c11" value="3" @input="fn_SetDataQ11(3)" :disabled="isDisableIns||isDisableInexpr">1년 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c11" value="4" @input="fn_SetDataQ11(4)" :disabled="isDisableIns||isDisableInexpr">2년 미만</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c11" value="5" @input="fn_SetDataQ11(5)" :disabled="isDisableIns||isDisableInexpr">2년 이상</mo-radio></div>
                      </ur-box-container>
                    </div> 
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card11.selectedValue}}</span></div>
                    </div>  
                  </div>   
                </div>
                <!-- 12. 위험성향 -->
                <div class="list-item" :class="{'on':card12.detailAreaOpen,'selected':card12.selected}" @click="card12.detailAreaOpen=!card12.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">12.</b><span class="ml4">위험성향</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">위험성향</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c12" value="1" @input="fn_SetDataQ12(1)" :disabled="isDisableIns||isReSuita">원금은 반드시 보존되어야 한다</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c12" value="2" @input="fn_SetDataQ12(2)" :disabled="isDisableIns||isReSuita">원금 보존을 중요하게 생각한다</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c12" value="3" @input="fn_SetDataQ12(3)" :disabled="isDisableIns||isReSuita">수익률이 어느 정도 되어야 한다</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c12" value="4" @input="fn_SetDataQ12(4)" :disabled="isDisableIns||isReSuita">수익률을 중시한다</mo-radio></div>
                      </ur-box-container>
                    </div> 
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card12.selectedValue}}</span></div>
                    </div>  
                  </div>   
                </div>
                <!-- 13. 손실감내수준 -->
                <div class="list-item" :class="{'on':card13.detailAreaOpen,'selected':card13.selected}" @click="card13.detailAreaOpen=!card13.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">13.</b><span class="ml4">손실감내수준</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">손실감내수준</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c13" value="1" @input="fn_SetDataQ13(1)" :disabled="isDisableIns||isReSuita">어떠한 경우에도 손실이 나면 안된다</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c13" value="2" @input="fn_SetDataQ13(2)" :disabled="isDisableIns||isReSuita">원금 기준 10% 이내는 감수할 수 있다</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c13" value="3" @input="fn_SetDataQ13(3)" :disabled="isDisableIns||isReSuita">원금 기준 20% 이내는 감수할 수 있다</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c13" value="4" @input="fn_SetDataQ13(4)" :disabled="isDisableIns||isReSuita">원금 기준 30% 이내는 감수할 수 있다</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c13" value="5" @input="fn_SetDataQ13(5)" :disabled="isDisableIns||isReSuita">원금 기준 40% 이내는 감수할 수 있다</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c13" value="6" @input="fn_SetDataQ13(6)" :disabled="isDisableIns||isReSuita">원금 기준 50% 이내는 감수할 수 있다</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c13" value="7" @input="fn_SetDataQ13(7)" :disabled="isDisableIns||isReSuita">원금 기준 100% 이내는 감수할 수 있다</mo-radio></div>
                      </ur-box-container>
                    </div> 
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card13.selectedValue}}</span></div>
                    </div>
                  </div>
                </div>
                <!-- 14. 외화보험 등 금융상품 이해수준 -->
                <div class="list-item" :class="{'on':card14.detailAreaOpen,'selected':card14.selected}" @click="card14.detailAreaOpen=!card14.detailAreaOpen">
                  <div class="list-item__contents">
                    <div class="q-title">
                      <div class="q-before"><b class="num">14.</b><span class="ml4">외화보험 등 금융상품 이해수준</span></div>
                      <div class="q-selected"><mo-icon icon="msp-is-checked" icon-size="27px"/><span class="ml4">외화보험 등 금융상품 이해수준</span></div>
                    </div>
                    <div class="q-content">
                      <ur-box-container alignV="start" componentid="" direction="row" class="q-chk-list mt4">
                        <div class="q-item"><mo-radio class="radio" v-model="c14" value="1" @input="fn_SetDataAssort(14,1)" :disabled="isDisableIns||isReSuita">이해 수준이 현저히 낮음</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c14" value="2" @input="fn_SetDataAssort(14,2)" :disabled="isDisableIns||isReSuita">외화보험 등 금융상품의 구조와 위험에 대해 일정 부분 이해</mo-radio></div>
                        <div class="q-item"><mo-radio class="radio" v-model="c14" value="3" @input="fn_SetDataAssort(14,3)" :disabled="isDisableIns||isReSuita">외화보험 등 금융상품의 구조와 위험에 대해 전체적으로 잘 이해</mo-radio></div>
                      </ur-box-container>
                    </div>
                    <div class="q-result">
                      <div class="fex-clumn mt10 pl31"><span class="crTy-blue4">{{card14.selectedValue}}</span></div>
                    </div>
                  </div>
                </div>

              </div><!-- //question_list -->
            </ur-box-container>  
          </template>
        </mo-collapsing-header-view>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button v-if="isShowBtnCmplt" componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="isDisableBtnCmplt" @click.stop="fn_btnSuitaInptCmpltClickHandler">{{cmpltBtnTxt}}</mo-button>
            <mo-button v-if="isShowBtnOldDataUse" componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click.stop="fn_btnOldDataUseClickHandler">기존정보 사용</mo-button>
            <mo-button v-if="isShowBtnRetry" componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="isDisableBtnCmplt" @click.stop="fn_btnSuitaInptRetryClickHandler">선택완료</mo-button>
          </div>
        </ur-box-container>
      </div>

      <!--START: confirm alert -->
      <mo-bottom-sheet ref="refConfirm" class="ns-bottom-sheet" noHeader preventTouchClose>
        <div class="customer-info-txt txt-center">
          <p class="ment" style="white-space:pre-line;">{{alertMsg}}</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
            <div class="relative-div" style="padding:0px 19px;">
              <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_AlertClose('cancel')">{{alertBtnLb1}}</mo-button>
              <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_AlertClose('ok')">{{alertBtnLb2}}</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!--END: confirm alert -->

      <!--START: alert -->
      <mo-bottom-sheet ref="refAlert" :close-btn="false" class="ns-bottom-sheet" noHeader>
        <div class="customer-info-txt txt-center">
          <p class="ment" style="white-space:pre-line;">{{alertMsg}}</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
            <div class="relative-div" style="padding:0px 19px;">
              <mo-button componentid="" color="primary" shape="primary" size="border" class="ns-btn-round blue" @click="fn_AlertClose()">{{alertBtnLb}}</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!--END: alert -->

      <!--START: alert -->
      <mo-bottom-sheet ref="refDefaultModal" :close-btn="false" class="ns-bottom-sheet" preventTouchClose>
        <template v-slot:title>적합성 원칙 필수 준수사항</template>
        <div class="customer-info-txt content-area">
          <p class="ment">
            ① 고객이 직접 작성하고 서명한 설문지가 있는 건에 限해 결과 입력<br>
                <!-- ※ 질문지 없이 입력할 경우 보험업法제95조의 3 [적합성 원칙]위반, <br> -->
            ※ 질문지 없이 입력할 경우 금융소비자보호법 제17조[적합성원칙] 위반<br><br>
                <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;자본시장법 제46조 [적합성의 원칙] 위반 <br><br> -->
            ② 설문 입력 후 질문지는 즉시 지점장 승인 요청 및 주임에게 제출<br>
            ※설문을 먼저 입력완료 후 승인 요청 하시기 바랍니다.<br><br>
            본 화면은 서면으로 고객의 서명을 받은 건을 입력하고 지점장에게 승인을 요청하는 화면입니다. 전자서명을 통한 적합성 진단은 &#0034;외화적합성(모바일)&#0034;을 이용하시기 바랍니다.
          </p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="primary" size="border" class="ns-btn-round blue" @click="fn_PrePopupBtnConfirmHandler()">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!--END: alert -->

      <!--START: alert -->
      <mo-bottom-sheet ref="refDignRsnAlert" :close-btn="false" class="ns-bottom-sheet" preventTouchClose>
      <template v-slot:title>재진단 확인</template>
      <div class="content-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area">
          <ur-box-container alignV="start" componentid="" direction="column" >
            <span class="fs17rem">고객님께서는 위험성향(적합성) 진단을 이미 하셨습니다. 위험성향(적합성)을 다시 진단 받고 싶으신 경우 재진단 사유를 선택해 주세요.</span>
            <span class="mt50 fs18rem">재진단 사유 선택</span>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-radio-wrap mt10">
              <div class="ns-radio-list">
                <mo-radio v-model="rDignRsnCd" value="01" class="">사실관계 착오</mo-radio>
                <mo-radio v-model="rDignRsnCd" value="02" class="mt20">단순오기</mo-radio>
              </div>
            </ur-box-container>
          </ur-box-container> 
        </ur-box-container>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="primary" size="medium" class="ns-btn-round white" @click="fn_CancelTSSPI723P()">취소</mo-button>
            <mo-button componentid="" color="primary" shape="primary" size="medium" class="ns-btn-round blue" @click="fn_ConfirmTSSPI723P()" :disabled="rDignRsnCd === ''">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!--END: alert -->

    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import moment from 'moment'
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Gaf from '@/ui/pi/common/Gaf'
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
import DateUtil from '@/ui/ps/comm/PSDateUtil'
import GafMainFunc from '@/ui/pi/common/GafMainFunc'
import EventBus from '@/ui/pi/common//EventBus.js'
import MSPCM129P from '@/ui/cm/MSPCM129P' // 고객조회
import MSPPI452P from '@/ui/pi/MSPPI452P' // 전문금융소비자 입력용
import MSPPI404P from '@/ui/pi/MSPPI404P' // 법인고객조회

export default {
  name: 'MSPPI550P',
  screenId: 'MSPPI550P',
  isBackKeyHandle: true, // 팝업일때 디바이스 백키 직접 핸들 처리 여부 옵션
  components: {
    'MSPCM129P': MSPCM129P, // 고객조회
    'MSPPI452P': MSPPI452P, // 전문금융소비자 입력용
    'MSPPI404P': MSPPI404P // 법인고객조회
  },
  created () {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClosePopup)

    this.isCustCheck = false
    this.isSuitaEditConfirm = false
    this.isChkErrorMsg = false
    this.isEditConfirm = false
    this.closeButtonEnabled = false // 닫기 버튼 막기

    this.headerVO = Object.assign({}, this.getStore('userInfo').getters.getUserInfo) // 사용자 정보 설정
    // GA 매니저 대행 컨설턴트 변경시
    if(this.$cmUtil.fn_GachangeTF()){
      this.headerVO.userId = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
    }

    console.log( 'this.pParams', this.pParams )

    if(this.pParams) {
      this.STR_suitaReportYN = this.pParams.suitaReportYN !== '' ? this.pParams.suitaReportPblYN : 'N'
      this.STR_suitaReportPblYN = this.pParams.suitaReportPblYN
    }

    if(!this.pParams) {
      // 전체메뉴에서 진입시
      Gaf.initStore('MSPPI550P')
      GafMainFunc.setStore('MSPPI550P')
      Gaf.clearPanelList()
      this.isDisableCust = false
      this.isShowBtnCmplt = true
      this.cmpltBtnTxt = '선택완료'
      this.btnStatus1 = '1'
    }
    Gaf.setProcessNo('1001')
    Gaf.addPanelList(this)

    window.vue.getStore('progress').dispatch('UPDATE', true)
    this.fn_init()
  },
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  mounted() {
    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  beforeDestroy () {
    EventBus.$off('LocalCallBack')
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClosePopup)
  },
  destroyed() {
  },
  watch: {
    card0Selected () {
      console.log('##### card0Selected :'+this.card0Selected)
    },
    card00Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card000Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card1Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card2Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card3Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card4Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card5Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card6Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card7Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card8Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card9Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card10Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card11Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card12Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card13Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''},
    card14Selected() {!this.oldDataUseFlag ? this.isDisableBtnCmplt = !this.fn_CheckAllItems() : ''}
  },
  methods: {
    fn_CheckAllItems() {
      if(this.aprvYn === 'Y') {
        return true
      }else {
        return (this.card00Selected && this.card000Selected && 
                this.card1Selected && this.card2Selected && this.card3Selected && this.card4Selected && this.card5Selected &&
                this.card6Selected && this.card7Selected && this.card8Selected && this.card9Selected && this.card10Selected &&
                this.card11Selected && this.card12Selected && this.card13Selected && this.card14Selected)
      }
    },
    fn_CheckItems() {
      return (this.card00Selected || this.card000Selected || 
              this.card1Selected || this.card2Selected || this.card3Selected || this.card4Selected || this.card5Selected ||
              this.card6Selected || this.card7Selected || this.card8Selected || this.card9Selected || this.card10Selected ||
              this.card11Selected || this.card12Selected || this.card13Selected || this.card14Selected)
    },
    /******************************************************************************
    * Function명 : fn_AlertClose
    * 설명       : 법인 대표명 검색 후 고객 ID로 고객 정보( 전화번호, Email ) 요청
    ******************************************************************************/
    fn_AlertClose(val) {
      if(val) {
        this.$refs.refConfirm.close()
        if(val === 'ok') {
          if(this.isRskEftAnlyRes) {
            this.fn_rskEftAnlyResPositive()
            return
          }
          if(this.isSuitaEditConfirm) {
            // 적합성 진단 이력이 있는 고객입니다.\n 새롭게 질문지를 받아오시고, 질문지 내용에\n 수정이 있는 건입니까?
            this.fn_suitaEditConfirmPositive()
            return
          }
        }else if(val === 'cancel') {
          if(this.isRskEftAnlyRes) {
            this.fn_rskEftAnlyResNegative()
            return
          }
          if(this.isSuitaEditConfirm) {
            // 적합성 진단 이력이 있는 고객입니다.\n 새롭게 질문지를 받아오시고, 질문지 내용에\n 수정이 있는 건입니까?
            this.fn_suitaEditConfirmNegative()
            return
          }
        }
      }else {
        this.$refs.refAlert.close()
        if(this.isRskEftAnlyAlert) {
          // 위험성향 진단 질문지 승인후 발행가능합니다.(진단일 + 3개월)
          this.isRskEftAnlyAlert = false
          return
        }
        if(this.isNotFndGstFlg) {
          this.isNotFndGstFlg = false
          return
        }
        if(this.isGENT && !this.isComplateGENSuita) {
          // 계약자가 법인인 경우 대표가 적합성진단을 하셔야 합니다.
          // 법인고객조회 화면 띄움
          this.fn_onPositiveGENT()
          return
        }
        if(this.isPrpnsDignDnl) {
          // 적합성 이력이 있는 경우, 적합성 진단 거부 시 알림
          this.isPrpnsDignDnl = false
          return
        }
        if(this.isCustCheck) {
          // 고객 선택 후 진행하시기 바랍니다.
          this.isCustCheck = false
          return
        }
        if(this.isUnAprv) {
          // 승인되지 않은 질문지가 있습니다.\n질문지 승인 후 재입력 가능합니다.
          this.isUnAprv = false
          return
        }
        if(this.isSuitaExpire) {
          // 적합성진단 유효기간(91일)이 만료되어 사용하실 수 없습니다. 적합성진단을 재수행 바랍니다.', // 3개월->91일 (2021.11_PJO)
          this.isSuitaExpire = false
          return
        }
        if(this.isBmngrMsg) {
          // 지점장에게 승인요청 하시기 바랍니다.
          this.isBmngrMsg = false
          return
        }
        if(this.isEditConfirm) {
          // 영업관리자 ‘질문지 승인’ 이후에 상품설명서(제안용) 발행이 가능합니다
          this.isEditConfirm = false
          this.fn_EditConfirm()
          return
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_searchCustomerNm
    * 설명       : 돋보기 아이콘 선택 TSSPI722P 호출 - 고객 이름으로 조회 요청
    ******************************************************************************/
    fn_searchCustomerNm () {
      if(!this.searchable) return
      let lv_Vm = this
      if(lv_Vm.isGENT) {
        lv_Vm.fn_onPositiveGENT() // 법인 고객 조회
      }else {
        lv_Vm.mspcm129p = lv_Vm.$bottomModal.open(MSPCM129P, {
          properties: {
            pCustNm: lv_Vm.displayCustNm // 고객명 파라미터
          },
          listeners: {
            onPopupConfirm: (pData) => {
              window.vue.getStore('progress').dispatch('UPDATE', true)
              lv_Vm.$bottomModal.close(lv_Vm.mspcm129p)
              lv_Vm.displayCustNm = pData.custNm
              lv_Vm.chnlCustId = pData.chnlCustId
              lv_Vm.fn_CustomSearch()
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_Vm.mspcm129p)
            }
          }
        },
        {
          properties: {noHeader: true}
        })
      }
    },
    /******************************************************************************
    * Function명 : fn_TSSPI190P_search
    * 설명       : 고객검색
    ******************************************************************************/
    fn_CustomSearch (input) {
      window.vue.getStore('progress').dispatch('UPDATE', true)

      // 선택한 데이터 초기화
      this.fn_clearn()

      let zCustInfoCmpxVO = {
        custNm: this.displayCustNm,
        cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId
      }
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        zCustInfoCmpxVO.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }

      let inputJson = {zCustInfoCmpxVO: zCustInfoCmpxVO}
      if (this.displayCustNm !== '') {
        piCommonUtil.invoke('URLPQL00009', inputJson, this.URLPQL00009_LocalCallBack, null, this.URLPQL00009_LocalCallBack, this, this)
      } else {
        window.vue.getStore('progress').dispatch('UPDATE', false)
      }
    },
    /******************************************************************************
    * Function명 : URLPQL00009_LocalCallBack
    * 설명       : URLPQL00009의 localCallBack
   ******************************************************************************/
    URLPQL00009_LocalCallBack (result) {
      console.log('URLPQL00009_LocalCallBack :: ', result)
      var lv_Vm = this

      // 서비스 후처리
      if (result === null || result === undefined || result.body === null || !piCommonUtil.isObj(result.zcustInfoCmpxVO.custInfoVO)) {
        lv_Vm.isNotFndGstFlg = true
        lv_Vm.alertMsg = lv_Vm.isNotFndGstMsg
        lv_Vm.$refs.refAlert.open()
        window.vue.getStore('progress').dispatch('UPDATE', false)
        return
      } else {
        let custInfoVO = null
        for(let i=0 ; i<result.zcustInfoCmpxVO.custInfoVO.length ; i++) {
          if(result.zcustInfoCmpxVO.custInfoVO[i].chnlCustId === lv_Vm.chnlCustId) {
            custInfoVO = result.zcustInfoCmpxVO.custInfoVO[i]
          }
        }
        if(custInfoVO === null && result.zcustInfoCmpxVO.custInfoVO.length > 0) {
          custInfoVO = result.zcustInfoCmpxVO.custInfoVO[0]
        }
        lv_Vm.custInfo.custNm = custInfoVO.custNm
        lv_Vm.custInfo.celno = custInfoVO.celno
        lv_Vm.custInfo.emailAddr = custInfoVO.emailAddr
        lv_Vm.custInfo.chnlCustId = custInfoVO.chnlCustId
        lv_Vm.custInfo.resCutNm = custInfoVO.custNm
        lv_Vm.custInfo.knb = custInfoVO.knb
        lv_Vm.custInfo.contrRrnFrno = custInfoVO.knb.substr(0, 6) // 주민번호 앞자리
        lv_Vm.custInfo.contrRrnBkno = custInfoVO.knb.substr(6) // 주민번호 뒷자리
      }
      lv_Vm.fn_setCustData()

      // 진입 후 알림 팝업 표시하면서 적합성 진단 수행
      lv_Vm.$refs.refDefaultModal.open()
      this.isDisableIns = false
      window.vue.getStore('progress').dispatch('UPDATE', false)
    },
    /****************************************************************************************
     * 함수 : fh_init
     * 설명 : 기본 정보, 화면 정보 초기화 처리
    ****************************************************************************************/
    fn_init () {
      let tmodel = Gaf.getObj('tmodel')

      if(!tmodel.rltnrInfo) {
        this.searchable = true
        this.card00.detailAreaOpen = true
        window.vue.getStore('progress').dispatch('UPDATE', false)
        // 전체메뉴에서 진입했고 고객명이 없을때 포커스 처리
        // setTimeout(() => {this.$refs.refCustNmTextField.focus()},1000)
        return
      }

      let rltnrInfo = ''
      // 적합성 진단자 - 계약자 기준으로 가져오기 로직수정 (ASR220700001 / 22.07.12_PJO)
      for ( let i = 0; i < tmodel.rltnrInfo.length; i++ ) {
        rltnrInfo = tmodel.rltnrInfo[i]
        if ( rltnrInfo.zzcontvInsrdCd === '11' ) {
          if ( rltnrInfo.zzcusDtlTypCd === '21') {
            // 단체 가입 처리 필요
            this.isGENT = true
          }
          this.rltnrInfo = rltnrInfo
          break
        }
      }

      if ( this.rltnrInfo === null || this.rltnrInfo === undefined || this.rltnrInfo === '' ) {
        this.isNotFndGstFlg = true
        this.alertMsg = this.isNotFndGstMsg
        setTimeout(() => {this.$refs.refAlert.open()},100)
        window.vue.getStore('progress').dispatch('UPDATE', false)
        return
      } else {
        this.custInfo.chnlCustId = this.chnlCustId = this.rltnrInfo.zaAgtFileId
        this.custInfo.zzcusDtlTypCd = this.rltnrInfo.zzcusDtlTypCd
      }

      // 적합성 진단 대상이 단체 여부 처리
      if ( this.isGENT === true ) {
        let abdapolicyList = Gaf.getGafObjByRefId('POLICY')

        if ( abdapolicyList.length > 0 ) {
          let abdapolicy = abdapolicyList[0] // 현재 Policy 정보
          let abdapolhldr = abdapolicy.abdapolhldr[0]
          let ztpqlCboextAddmAbdapolhldr = abdapolhldr.ztpqlCboextAddmAbdapolhldr

          if ( ztpqlCboextAddmAbdapolhldr.zaGNm !== '' && ztpqlCboextAddmAbdapolhldr.zaResntNm !== '' &&
          ztpqlCboextAddmAbdapolhldr.zaGNm !== undefined && ztpqlCboextAddmAbdapolhldr.zaResntNm !== undefined ) {
            // 단체명의 대표자가 있는경우
            this.searchable = false
            this.custInfo.custNm = ztpqlCboextAddmAbdapolhldr.zaResntNm // 666
            this.custInfo.knb = ztpqlCboextAddmAbdapolhldr.zaResntRrnId // 계약자 주민번호
            this.custInfo.contrRrnFrno = ztpqlCboextAddmAbdapolhldr.zaResntRrnId.substr(0, 6) // 주민번호 앞자리
            this.custInfo.contrRrnBkno = ztpqlCboextAddmAbdapolhldr.zaResntRrnId.substr(6) // 주민번호 뒷자리
            this.custInfo.contrChnlCustId = ztpqlCboextAddmAbdapolhldr.zaRentAgtFileId // 계약자 채널고객ID
            this.custInfo.insrAge = GafMainFunc.calcAge(ztpqlCboextAddmAbdapolhldr.zaResntRrnId, piCommonUtil.getCurrentDate(), 'insurance')
            this.isComplateGENSuita = true
          } else {
            // 단체명의 대표자가 없는경우
            this.searchable = true
            this.custInfo.custNm = this.displayCustNm = ''
            this.custInfo.contrRrnFrno = ''
            this.custInfo.contrRrnBkno = ''
            this.custInfo.contrChnlCustId = ''
            this.custInfo.insrAge = 0
            this.isComplateGENSuita = false
            // 계약자가 법인인 경우 대표가 적합성 진단을 하셔야 합니다. 알럿 후 처리 필요
            this.alertMsg = '계약자가 법인인 경우 대표가 적합성진단을 하셔야 합니다.'
            setTimeout(() => {this.$refs.refAlert.open()},100)
            window.vue.getStore('progress').dispatch('UPDATE', false)
            return
          }
        }
      } else { // 개인 계약자
        this.searchable = false
        this.custInfo.custNm = this.displayCustNm = rltnrInfo.zaInsrdNm // 계약자명
        this.custInfo.contrRrnFrno = rltnrInfo.zzcustRrnId.substr(0, 6) // 주민번호 앞자리
        this.custInfo.contrRrnBkno = rltnrInfo.zzcustRrnId.substr(6) // 주민번호 뒷자리
        this.custInfo.knb = rltnrInfo.zzcustRrnId
        this.custInfo.insrAge = rltnrInfo.ageatentryVl // 보험나이
      }
      this.fn_setCustData()
      this.fn_CustomSearch()
    },
    /******************************************************************************
    * Function명 : fn_onPositiveGENT
    * 설명       : 단체 가입으로 법인 대표명 검색이 필요할 경우 ComfirmPopup 표시
    ******************************************************************************/
    fn_onPositiveGENT () {
      this.isGENFlg = false
      this.isTSSPI310P = true

      let lv_Vm = this
      lv_Vm.msppi404p = lv_Vm.$bottomModal.open(MSPPI404P, {
        properties: {
          pCustNm: lv_Vm.displayCustNm || '' // 고객명 파라미터
        },
        listeners: {
          confirmPopup: (pData) => {
            window.vue.getStore('progress').dispatch('UPDATE', true)
            lv_Vm.$bottomModal.close(lv_Vm.msppi404p)
            lv_Vm.displayCustNm = pData.custNm
            lv_Vm.chnlCustId = pData.chnlCustId
            if(!pData.emailAddr) {
                pData.emailAddr = ''
              }
            lv_Vm.fn_ConfirmFromPopup(pData)
          },
          cancelPopup: (pData) => {
            lv_Vm.$bottomModal.close(lv_Vm.msppi404p)
            if(pData && pData.action === 'goCM') {
              // 필수컨설팅동의 화면으로 이동
              let o = {}
              o.name = 'MSPCM002M'
              o.params = {}
              o.params.chnlCustId = pData.param.chnlCustId
              o.params.cnsltNo = lv_Vm.cnsltNo
              o.params.uuid = new Date().getTime()
              o.params.detailPopupOpen = true
              lv_Vm.$emit('cancelPopup', o)
            }
          }
        }
      },
      {
        properties: {noHeader: true}
      })
    },
    /******************************************************************************
    * Function명 : fn_ConfirmFromPopup
    * 설명       : 팝업 검색 후 다시 개인 정보(전화 번호, Email 정보) 검색 요청
    *              fn_reqCustInfoAftCorpRepr()호출
    ******************************************************************************/
    fn_ConfirmFromPopup (lParam) {
      this.lv_Items = lParam

      this.isTSSPI310P = false
      this.isTSSPI722P = false

      this.custInfo.custNm = this.displayCustNm = lParam.custNm
      this.custInfo.phoneNum = lParam.celno
      this.custInfo.emailAddr = lParam.emailAddr
      this.custInfo.chnlCustId = lParam.chnlCustId
      this.custInfo.insrAge = lParam.age
      this.custInfo.knb = lParam.knb // : "7107311******"

      if ( this.custInfo.knb !== undefined ) {
        this.custInfo.contrRrnFrno = this.custInfo.knb.substr(0, 6) // 주민번호 앞자리
        this.custInfo.contrRrnBkno = this.custInfo.knb.substr(6) // 주민번호 뒷자리
      }

      this.isComplateGENSuita = true
      this.fn_CustomSearch()
    },
    /****************************************************************************************
     * 함수 : fn_setCustData
     * 설명 : 데이터를 화면에 설정처리
    ****************************************************************************************/
    fn_setCustData () {
      if ( this.custInfo.contrRrnFrno !== '') {
        this.custInfo.lbContrRrn = this.custInfo.contrRrnFrno + '-' + this.custInfo.contrRrnBkno.substr(0, 1) + '******'
      } else {
        this.custInfo.lbContrRrn = '-'
      }

      this.isDisableBtnCmplt = true // 적합성선택완료
      // this.isDisableBtnDnl = true // 성향진단거부
      this.isDisableBtnExprt = true // 전문금융소비자
      this.isDisableBtnPlb = true // 진단결과 발행

      window.vue.getStore('progress').dispatch('UPDATE', false)
    },
    /****************************************************************************************
     * 함수 : fn_ValidationSuitaData
     * 설명 : 분석결과전 Validation 체크
    ****************************************************************************************/
    fn_ValidationSuitaData () {
      // 선택 고객 없을시
      if ( this.custInfo.custNm === '' || this.custInfo.custNm === ' ' ) {
        this.isCustCheck = true
        this.alertMsg = this.ErrorMsg1
        this.$refs.refAlert.open()
        return false
      }

      // 미승인인 경우 알림 출력
      if (this.aprvYn === 'N') {
        this.isUnAprv = true
        this.alertMsg = this.ErrorMsg3
        this.$refs.refAlert.open()
        return false
      }

      // 적합성재진단 대상인데, 재진단사유 입력이 안된경우 재진단  팝업 오픈 (20220118_PJO)
      if ( this.isReSuita && this.rDignRsnCd === '' ) {
        // this.alertPopupSuitaFlag = true // alertPopupSuitaMsg = '재진단사유가 입력되지 않았습니다.\n\n재진단 사유 입력 하시겠습니까?'
        // this.isTSSPI723PConfirm = false
        // return false
      }

      // 이전에 적합성 검사한 이력이 있는 경우
      // '적합성 진단 이력이 있는 고객입니다.\n 새롭게 질문지를 받아오시고, 질문지 내용에\n 수정이 있는 건입니까?', 경고창 생성
      if ( this.strMB_prextSuitaYn === 'Y') {
        this.isSuitaEditConfirm = true
        this.alertMsg = this.ErrorMsg2
        this.alertBtnLb1 = '아니오'
        this.alertBtnLb2 = '예'
        this.$refs.refConfirm.open()
        return false
      }

      // this.fn_checkInputAnswer() // [적합성 입력 값] 체크
      if (this.fn_checkInputAnswer()) {
        return false
      }
      this.isDisableBtnPlb = true // 위치이동 (20220118_PJO
      this.inputDone = true
      return true
    },
    /****************************************************************************************
     * 함수 : fn_btnSuitaInptCmpltClickHandler
     * 설명 : [적합성 선택완료] 버튼 클릭 시 - ( S2 ) 전 처리
    ****************************************************************************************/
    fn_btnSuitaInptCmpltClickHandler () {
      if(this.btnStatus1 === '1') {
        // 선택완료
        if ( this.fn_ValidationSuitaData() === false ) {
          return
        }
        window.vue.getStore('progress').dispatch('UPDATE', true)
        // 입력한 응답에 대한 데이터 처리 ( 요청도 해야되고 할게 많음 )
        this.fn_setSuitaAnswerData()
        // 결과 요청 인터페이스 호출
        this.fn_RequestSuitaDign()
      }else {
        // 적합성 발행
        this.fn_cbRskEftAnlyResConfirm()
      }
    },
    /****************************************************************************************
    * 함수 : fn_btnSuitaInptRetryClickHandler
    * 설명 : 재진단 선택완료 버튼 클릭 핸들러
    ****************************************************************************************/
    fn_btnSuitaInptRetryClickHandler () {
      // 선택완료
      if ( this.fn_ValidationSuitaData() === false ) {
        return
      }
      window.vue.getStore('progress').dispatch('UPDATE', true)
      // 입력한 응답에 대한 데이터 처리 ( 요청도 해야되고 할게 많음 )
      this.fn_setSuitaAnswerData()
      // 결과 요청 인터페이스 호출
      this.fn_RequestSuitaDign()
    },
    // 기존정보 사용 버튼 클릭 핸들러
    fn_btnOldDataUseClickHandler() {
      if(this.dcSuitaCnsntVO) {
        this.fn_clearn() // 화면 초기화
        this.oldDataUseFlag = true // 기존정보사용 플래그 셋팅
        this.isShowBtnCmplt = true // 선택완료, 진단결과 발행 버튼
        this.isDisableBtnCmplt = true // 선택완료, 진단결과 발행 버튼
        this.isShowBtnOldDataUse = this.isShowBtnRetry = false // 기존정보 사용 , 선택완료 버튼
        this.cmpltBtnTxt = '선택완료'
        this.btnStatus1 = '1' // 1-선택완료, 2-진단결과발행
        this.fn_RequestSuitaQstnInveHandler(this.dcSuitaCnsntVO) // 기존정보 화면 셋팅
      }else {
        console.log('######### 기존데이터 없음')
      }
    },
    /****************************************************************************************
     * 함수 : fn_checkInputAnswer
     * 설명 : [적합성 입력 값] 체크
    ****************************************************************************************/
    fn_checkInputAnswer () {
      this.ChkErrorMsg = ''

      // 입력값 체크
      if ( this.formInput.choice01.length < 1 ) {
        this.ChkErrorMsg = this.ErrorMsgs.label01
      } else if ( this.formInput.choice01[0] === '2' && piCommonUtil.isEmpty(this.SuitaExportVO.exprtFncCspTypCd) ) { // 전문가금융소비자유형코드) {
        this.ChkErrorMsg = this.ErrorMsgs.label012
      } else if ( this.formInput.choice02.length === 0 ) {
        this.ChkErrorMsg = this.ErrorMsgs.label02
      // } else if ( this.formInput.choice02[0] !== '1' && this.formInput.choice02[0] !== '2' && this.formInput.choice03.length === 0 ) {
      //   this.ChkErrorMsg = this.ErrorMsgs.label03
      } else if ( this.formInput.choice1.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label1
      } else if ( this.formInput.choice2.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label2
      } else if ( this.formInput.choice3.length === 0 ) { // } else if ( this.formInput.choice3.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label3
      } else if ( this.formInput.choice4.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label4
      } else if ( this.formInput.choice5.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label5
      } else if ( this.formInput.choice6.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label6
      } else if ( this.formInput.choice7.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label7
      } else if ( this.formInput.choice8.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label8
      } else if ( this.formInput.choice9.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label9
      } else if ( this.formInput.choice10.length === 0 ) { // } else if ( this.formInput.choice10.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label10
      } else if ( this.formInput.choice11.key === '0' ) { // } else if ( this.formInput.choice11.length === 0 ) {
        this.ChkErrorMsg = this.ErrorMsgs.label11
      } else if ( this.formInput.choice12.key === '0' ) { // } else if ( this.formInput.choice12.length === 0 ) {
        this.ChkErrorMsg = this.ErrorMsgs.label12
      } else if ( this.formInput.choice13.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label13
      } else if ( this.formInput.choice14.key === '0' ) {
        this.ChkErrorMsg = this.ErrorMsgs.label14
      } else {
        console.log(' answer is full! OK ')
        this.ChkErrorMsg = ''
      }

      if ( this.ChkErrorMsg.length !== 0 ) {
        this.isChkErrorMsg = true
      } else {
        this.isChkErrorMsg = false
      }
      return this.isChkErrorMsg
    },
    /******************************************************************************
    * 함수 : fn_RequestSuitaQstnInve
    * 설명 : 가입 고객의 적합성 정보 조회 - 적합성 입력정보 조회 - invoke call
    ******************************************************************************/
    fn_RequestSuitaQstnInve () {
      // 외화적합성단건조회 (레이아웃 항목은 다 보내고, 필수값만 채워서 보낸ㄴ다.)
      var param = {
        ZA_INFO_PRCUS_CNSNT_ID: '', // 1     ZA_INFO_PRCUS_CNSNT_ID    정보활용동의ID
        ZA_CHNL_ID: this.headerVO.userId, // 2     ZA_CHNL_ID    채널ID    컨설턴트번호 / 조회시 필수값
        NAME_LAST: this.custInfo.custNm, // 3     NAME_LAST    비즈니스 파트너의 성(개인)    계약자명 / 조회시 필수값
        IDNUMBER: this.custInfo.knb, // 4     IDNUMBER    ID 번호    계약자주민등록번호 / 조회시 필수값
        ZA_SUITA_CNSNT_TYP_CD: '02', // 5     ZA_SUITA_CNSNT_TYP_CD    적합성동의유형코드     02 외화적합성/ 조회시 필수값
        ZA_AVL_STR_YMD: '', // 6     ZA_AVL_STR_YMD    유효시작일자
        ZA_AVL_END_YMD: '', // 7     ZA_AVL_END_YMD    유효종료일자
        ZA_INFO_PRCUS_CNSNT_STAT_CD: '', // 8     ZA_INFO_PRCUS_CNSNT_STAT_CD    정보활용동의상태코드
        ZA_APL_FOF_DEPT_CD: '', // 9     ZA_APL_FOF_DEPT_CD    신청지점부서코드
        ZA_APLR_ENO: '', // 10    ZA_APLR_ENO    신청자사번
        ZA_APL_DTM: '', // 11    ZA_APL_DTM    신청일시
        ZA_APRVR_ENO: '', // 12    ZA_APRVR_ENO    승인자사번
        ZA_APRV_DTM: '', // 13    ZA_APRV_DTM    승인일시
        ZA_CANC_TRTR_ENO: '', // 14    ZA_CANC_TRTR_ENO    취소처리자사번
        ZA_CANC_DTM: '', // 15    ZA_CANC_DTM    취소일시
        ZA_INFO_PRCUS_CNSNT_CANC_RSN: '', // 16    ZA_INFO_PRCUS_CNSNT_CANC_RSN    정보활용동의취소사유
        ZA_QSTN_PRDT_RSLT_CD: '', // 17    ZA_QSTN_PRDT_RSLT_CD    설문상품결과코드
        ZA_SUITA_ANLY_RSLT_CD: '', // 18    ZA_SUITA_ANLY_RSLT_CD    적합성분석결과코드
        ZA_R_DIGN_YN: '', // 19    ZA_R_DIGN_YN    재진단여부
        ZA_R_DIGN_RSN_CD: '', // 20    ZA_R_DIGN_RSN_CD    재진단사유코드
        T_SUITA_CNTNT: [] // 21    T_SUITA_CNTNT    적합성설문내역리스트
        // ZA_SUITA_QUEST_CNTNT: '',  // 22    ZA_SUITA_QUEST_CNTNT    적합성질문내용
        // ZA_SUITA_ANSW_CNTNT: '' // 23    ZA_SUITA_ANSW_CNTNT    적합성답변내용
      }
      window.vue.getStore('progress').dispatch('UPDATE', true)
      piCommonUtil.invoke('UF1MDI0393', param, this.fn_RequestSuitaQstnInveHandler, null, this.fn_RequestSuitaQstnInveHandler, this, this)
    },
    /********************************************************************************************
     * 함수 : fn_RequestSuitaQstnInveHandler
     * 설명 : 적합성 정보 조회(S1) 요청의 응답 처리
     ********************************************************************************************/
    fn_RequestSuitaQstnInveHandler (result) {
      if(!this.dcSuitaCnsntVO) this.dcSuitaCnsntVO = {}
      this.dcSuitaCnsntVO = result
      this.fn_prtSuitaInfo(result)
    },
    /********************************************************************************************
     * 함수 : fn_prtSuitaInfo
     * 설명 : 조회된 적합성 정보 화면 출력
     * Param : pISuitaVO - 외화 적합성 정보 조회(S1) 응답(Response) 정보
    ********************************************************************************************/
    fn_prtSuitaInfo (_pISuitaVO) {
      // 외화적합성조회는 유효종료일(91) 이내 진단이력만 조회 됨으로
      // 만기체크 따로 하지 않는다.
      // 재진단 여부도 if 결과 값 수신된 이력으로 세팅한다.
      this.isSuitaExpire = false // 초기값 fasle (외화적합성은  유효종료일(91) 이내 진단이력만 보내줌으로 만기체크 따로 하지 않는다 )
      this.isReSuita = false // 재진단 여부체크
      let pISuitaVO = _pISuitaVO // ★수정필요 (pISuitaVO 기존vo 기준으로 세팅)
      pISuitaVO.cnsltNo = _pISuitaVO.za_CHNL_ID

      pISuitaVO.ZA_SUITA_CNSNT_TYP_CD = _pISuitaVO.za_SUITA_CNSNT_TYP_CD // 5     ZA_SUITA_CNSNT_TYP_CD    적합성동의유형코드     02 외화적합성/ 조회시 필수값
      pISuitaVO.ZA_AVL_STR_YMD = _pISuitaVO.za_AVL_STR_YMD // 6     ZA_AVL_STR_YMD    유효시작일자
      pISuitaVO.ZA_AVL_END_YMD = _pISuitaVO.za_AVL_END_YMD // 7     ZA_AVL_END_YMD    유효종료일자
      pISuitaVO.ZA_INFO_PRCUS_CNSNT_STAT_CD = _pISuitaVO.za_INFO_PRCUS_CNSNT_STAT_CD // 8     ZA_INFO_PRCUS_CNSNT_STAT_CD    정보활용동의상태코드
      pISuitaVO.ZA_APL_FOF_DEPT_CD = _pISuitaVO.za_APL_FOF_DEPT_CD // 9     ZA_APL_FOF_DEPT_CD    신청지점부서코드
      pISuitaVO.ZA_APL_DTM = _pISuitaVO.za_APL_DTM // 11    ZA_APL_DTM    신청일시
      pISuitaVO.ZA_APRVR_ENO = _pISuitaVO.za_APRVR_ENO // 12    ZA_APRVR_ENO    승인자사번
      pISuitaVO.ZA_APRV_DTM = _pISuitaVO.za_APRV_DTM // 13    ZA_APRV_DTM    승인일시
      pISuitaVO.ZA_CANC_TRTR_ENO = _pISuitaVO.za_CANC_TRTR_ENO // 14    ZA_CANC_TRTR_ENO    취소처리자사번
      pISuitaVO.ZA_CANC_DTM = _pISuitaVO.za_CANC_DTM// 15    ZA_CANC_DTM    취소일시
      pISuitaVO.ZA_INFO_PRCUS_CNSNT_CANC_RSN = _pISuitaVO.za_INFO_PRCUS_CNSNT_CANC_RSN // 16    ZA_INFO_PRCUS_CNSNT_CANC_RSN    정보활용동의취소사유
      pISuitaVO.ZA_QSTN_PRDT_RSLT_CD = _pISuitaVO.za_QSTN_PRDT_RSLT_CD // 17    ZA_QSTN_PRDT_RSLT_CD    설문상품결과코드
      pISuitaVO.ZA_SUITA_ANLY_RSLT_CD = _pISuitaVO.za_SUITA_ANLY_RSLT_CD // 18    ZA_SUITA_ANLY_RSLT_CD    적합성분석결과코드
      pISuitaVO.ZA_R_DIGN_YN = _pISuitaVO.za_R_DIGN_YN // 19    ZA_R_DIGN_YN    재진단여부
      pISuitaVO.ZA_R_DIGN_RSN_CD = _pISuitaVO.za_R_DIGN_RSN_CD // 20    ZA_R_DIGN_RSN_CD    재진단사유코드

      this.closeButtonEnabled = true // 적합성보고서 발행용 버튼 처리
      if (this.STR_suitaReportYN === 'Y') { // 적합성 보고서 발행 대상. 초기값로는 가설 메인에서 값을 전달 해줌
        this.isDisable = false
        
        this.isDisableBtnExprt = true // 전문금융소비자
        this.isDisableBtnPlb = false // 진단결과 발행
        this.isShowBtnCmplt = true // 선택완료, 진단결과 발행 버튼 show
        this.cmpltBtnTxt = '진단결과 발행'
        this.btnStatus1 = '2' // 1-선택완료, 2-진단결과발행
        this.isDisableBtnCmplt = false // 진단결과 발행 버튼 활성화
        this.isShowBtnOldDataUse = false // 기존정보 사용 버튼
        this.isShowBtnRetry = false // 다시진단하기, 변경 재진단 버튼

        // 보고서미발행시 닫기 불가
        if (this.STR_suitaReportPblYN !== 'Y') {
          this.closeButtonEnabled = false // 보고서 발행이 되어야하는 경우는 닫지 못하게 하는 처리
        }
      }
      // 적합성 입력정보 조회
      // 1. 기존 설문정보가 있는 경우
      // ① 적합성승인을 받은 경우, (설계사상이에 상관없이)기존 설문조사정보를 표시한다.
      // ② 적합성승인을 안 받은 경우, 설계사가 상이 할 경우, 설문조사 초기화면을 표시한다.
      // 2. 기존 설문정보가 없는 경우, 설문조사 초기화면을 표시한다.
      // ZA_INFO_PRCUS_CNSNT_STAT_CD 정보활용동의상태코드 (01 승인요청 02 승인 03 승인취소)
      // T_SUITA_CNTNT
      // ZA_SUITA_QUEST_CNTNT 적합성질문내용
      // ZA_SUITA_ANSW_CNTNT 적합성답변내용
      if ( pISuitaVO === null || pISuitaVO === undefined ) { // 적합성이력 미존재
        this.strMB_prextSuitaYn = 'N'
        // pISuitaVO.inqrYn = 'N'
        this.isDisableIns = false
        this.card00.detailAreaOpen = true
        window.vue.getStore('progress').dispatch('UPDATE', false)
        return
      } else {
        let _ZA_INFO_PRCUS_CNSNT_STAT_CD = pISuitaVO.za_INFO_PRCUS_CNSNT_STAT_CD === undefined || ( String(pISuitaVO.za_INFO_PRCUS_CNSNT_STAT_CD).trim() === '' ) ? '' : pISuitaVO.za_INFO_PRCUS_CNSNT_STAT_CD
        console.log('>>UF1MDI393결과값:_ZA_INFO_PRCUS_CNSNT_STAT_CD: [' + _ZA_INFO_PRCUS_CNSNT_STAT_CD + ']')
        if ( _ZA_INFO_PRCUS_CNSNT_STAT_CD === '99' || _ZA_INFO_PRCUS_CNSNT_STAT_CD === '' ) { // 적합성이력 없음
          this.strMB_prextSuitaYn = 'N'
          this.isDisableIns = false
          this.card00.detailAreaOpen = true
          window.vue.getStore('progress').dispatch('UPDATE', false)
          return
        } else if (pISuitaVO.za_INFO_PRCUS_CNSNT_STAT_CD === '02') { // 승인
          pISuitaVO.aprvYn = 'Y'
          this.strMB_prextSuitaYn = 'Y' // 적합성 이력이 있음
          this.isDisableIns = true
        } else {
          pISuitaVO.aprvYn = 'N'
          if (this.headerVO.userId !== pISuitaVO.cnsltNo) {
            this.strMB_prextSuitaYn = 'N'
            this.isDisableIns = false
            this.card00.detailAreaOpen = true
            window.vue.getStore('progress').dispatch('UPDATE', false)
            return
          }
          this.strMB_prextSuitaYn = 'N'
        }
      }
      // ---------------------------------------------------------------------
      // 기존이력 조회 있는 경우만 아래로직
      // ---------------------------------------------------------------------
      // pISuitaVO.inqrYn = 'Y'
      this.strMB_prextSuitaYn = 'Y' // 적합성 이력 있음 ( Y이면 메세지 보여짐: '적합성 진단 이력이 있는 고객입니다.\n 새롭게 질문지를 받아오시고, 질문지 내용에\n 수정이 있는 건입니까?', 경고창 생성)
      this.aprvYn = ( pISuitaVO.za_INFO_PRCUS_CNSNT_STAT_CD === '02') ? 'Y' : 'N' // 승인/미승인 여부
      // if ( pISuitaVO.za_R_DIGN_YN === 'Y') { // 재진단여부
      //   this.isReSuita = true
      // }
      // 재진단여부판단기준: ZA_APL_DTM 신청일시 8자리가 오늘날짜면 재진단 Y 임
      let _ZA_APL_DTM = pISuitaVO.za_APL_DTM === undefined || (String(pISuitaVO.za_APL_DTM).trim() === '' ) ? '' : pISuitaVO.za_APL_DTM.substr(0, 8)
      let toDay = ''
      if(window.vue.getStore('psStore')) {
        toDay = DateUtil.fn_CurrentDate()
      }else {
        toDay = DateUtil.fn_DateToString(new Date(), 'yyyyMMdd')
      }
      // _ZA_APL_DTM = '20220613' // 삭제할것
      if ( _ZA_APL_DTM === toDay ) {
        this.isReSuita = true
      }
      // 적합성 재진단 여부체크 -> 2023-05-24 승인건이고 유효기간이 있으면 재진단 가능하게 변경 by 기획 김은비 과장
      // if ( _ZA_APL_DTM !== '' && this.aprvYn === 'Y') {
      //   this.isReSuita = true
      // }

      this.suitaDignId = pISuitaVO.za_INFO_PRCUS_CNSNT_ID // 정보활용동의ID
      /* 질문답변내용---------------------------------
      pISuitaVO.구 ID 로 화면 세팅되는 값이 많아서
      아래 항목만 구ID로 다시 매핑해줌
      _pISuitaVO ->  pISuitaVO
      신                              구
      ZA_EXPRT_FNC_CSP_YN            exprtFncCspYn          전문금융소비자_전문가금융소비자여부
      ZA_EXPRT_FNC_CSP_TYP_CD        exprtFncCspTypCd       전문금융소비자_전문가금융소비자유형코드
      ZA_GEN_FNC_CSP_SAME_TRTMT_YN   genFncCspSameTrtmtYn   전문금융소비자_일반금융소비자동일대우여부
      ZA_FNC_CNSM_RSTC_OBJ_YN        fncCnsmRstcObjYn       취약금융소비자_금융소비제한대상여부
      ZA_QSTN_HIGH_AGE_RSK_YN        highAgeRskYn           취약금융소비자_설문고연령위험여부
      ZA_MIOR_YN                     miorYn                 취약금융소비자_미성년자여부
      ZA_QSTN_ICMP_YN                icmpYn                 취약금융소비자_설문금치산자여부
      ZA_LEGL_AGNT_YN                leglAgntYn             취약금융소비자_법적대리인여부
      */
      let _T_SUITA_CNTNT = pISuitaVO.t_SUITA_CNTNT
      for ( let i = 0; i < _T_SUITA_CNTNT.length; i++ ) {
        // 전문금융소비자
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_EXPRT_FNC_CSP_YN') {
          pISuitaVO.exprtFncCspYn = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          pISuitaVO.ZA_EXPRT_FNC_CSP_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_EXPRT_FNC_CSP_TYP_CD') {
          pISuitaVO.exprtFncCspTypCd = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          pISuitaVO.zaEXPRT_FNC_CSP_TYP_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_GEN_FNC_CSP_SAME_TRTMT_YN') {
          pISuitaVO.genFncCspSameTrtmtYn = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          pISuitaVO.ZA_GEN_FNC_CSP_SAME_TRTMT_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 취약금융소비자
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_FNC_CNSM_RSTC_OBJ_YN') {
          pISuitaVO.fncCnsmRstcObjYn = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          pISuitaVO.ZA_FNC_CNSM_RSTC_OBJ_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_QSTN_HIGH_AGE_RSK_YN') {
          pISuitaVO.highAgeRskYn = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          pISuitaVO.ZA_QSTN_HIGH_AGE_RSK_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_MIOR_YN') {
          pISuitaVO.exprtFncCspYn = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          pISuitaVO.ZA_EXPRT_FNC_CSP_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_QSTN_ICMP_YN') {
          pISuitaVO.icmpYn = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          pISuitaVO.ZA_QSTN_ICMP_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_LEGL_AGNT_YN') {
          pISuitaVO.leglAgntYn = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          pISuitaVO.ZA_LEGL_AGNT_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문1
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_QSTN_FCURR_ENT_PPS_CD') {
          pISuitaVO.ZA_QSTN_FCURR_ENT_PPS_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문2
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_QSTN_ENT_PPS_CD') {
          pISuitaVO.ZA_QSTN_ENT_PPS_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문03_활용계획해외거주여부    za_PRCUS_PLN_OVERS_DW_YN
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_PRCUS_PLN_OVERS_DW_YN') {
          pISuitaVO.ZA_PRCUS_PLN_OVERS_DW_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문03_활용계획해외여행여부    za_PRCUS_PLN_OVERS_TRV_YN
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_PRCUS_PLN_OVERS_TRV_YN') {
          pISuitaVO.ZA_PRCUS_PLN_OVERS_TRV_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_PRCUS_PLN_OVERS_TRV_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문03_활용계획생활자금여부    za_PRCUS_PLN_LVNG_FD_YN
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_PRCUS_PLN_LVNG_FD_YN') {
          pISuitaVO.ZA_PRCUS_PLN_LVNG_FD_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_PRCUS_PLN_LVNG_FD_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문03_활용계획상속여부       za_PRCUS_PLN_IHRT_YN
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_PRCUS_PLN_IHRT_YN') {
          pISuitaVO.ZA_PRCUS_PLN_IHRT_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_PRCUS_PLN_IHRT_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문03_활용계획투자여부       za_PRCUS_PLN_IVT_YN
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_PRCUS_PLN_IVT_YN') {
          pISuitaVO.ZA_PRCUS_PLN_IVT_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_PRCUS_PLN_IVT_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문03_활용계획보장자산여부    za_PRCUS_PLN_COVR_ASET_YN
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_PRCUS_PLN_COVR_ASET_YN') {
          pISuitaVO.ZA_PRCUS_PLN_COVR_ASET_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_PRCUS_PLN_COVR_ASET_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문04_설문세대소득코드 za_QSTN_HOSH_INCM_CD choice4
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_QSTN_HOSH_INCM_CD') {
          pISuitaVO.ZA_QSTN_HOSH_INCM_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_QSTN_HOSH_INCM_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문05_설문보험료비율코드     za_QSTN_PRM_RTO_CD    choice5
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_QSTN_PRM_RTO_CD') {
          pISuitaVO.ZA_QSTN_PRM_RTO_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_QSTN_PRM_RTO_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문06_설문추가납입보험료코드     za_QSTN_ADPAD_PRM_CD    choice6
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_QSTN_ADPAD_PRM_CD') {
          pISuitaVO.ZA_QSTN_ADPAD_PRM_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_QSTN_ADPAD_PRM_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문07_설문납입기간코드    za_QSTN_PMPRD_CD    choice7
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_QSTN_PMPRD_CD') {
          pISuitaVO.ZA_QSTN_PMPRD_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_QSTN_PMPRD_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문08_설문계약유지기간코드    za_QSTN_CONT_UKEP_PRD_CD    choice8
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_QSTN_CONT_UKEP_PRD_CD') {
          pISuitaVO.ZA_QSTN_CONT_UKEP_PRD_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_QSTN_CONT_UKEP_PRD_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문09_설문중도해지가능성코드    za_QSTN_MID_RSC_PSBLY_CD    choice9
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_QSTN_MID_RSC_PSBLY_CD') {
          pISuitaVO.ZA_QSTN_MID_RSC_PSBLY_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_QSTN_MID_RSC_PSBLY_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문10_설문경험외화여부    za_QSTN_EXPR_FCURR_YN    choice10
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_QSTN_EXPR_FCURR_YN') {
          pISuitaVO.ZA_QSTN_EXPR_FCURR_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_QSTN_EXPR_FCURR_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문10_설문경험외화예금여부    za_QSTN_EXPR_FCURR_DPST_YN    choice10
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_QSTN_EXPR_FCURR_DPST_YN') {
          pISuitaVO.ZA_QSTN_EXPR_FCURR_DPST_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_QSTN_EXPR_FCURR_DPST_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문10_설문경험외화펀드여부    za_QSTN_EXPR_FCURR_FND_YN    choice10
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_QSTN_EXPR_FCURR_FND_YN') {
          pISuitaVO.ZA_QSTN_EXPR_FCURR_FND_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_QSTN_EXPR_FCURR_FND_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문10_설문경험외화주식여부    za_QSTN_EXPR_FCURR_STCK_YN    choice10
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_QSTN_EXPR_FCURR_STCK_YN') {
          pISuitaVO.ZA_QSTN_EXPR_FCURR_STCK_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_QSTN_EXPR_FCURR_STCK_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문10_설문경험외화저축여부    za_QSTN_EXPR_FCURR_SAV_YN    choice10
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_QSTN_EXPR_FCURR_SAV_YN') {
          pISuitaVO.ZA_QSTN_EXPR_FCURR_SAV_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_QSTN_EXPR_FCURR_SAV_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문10_설문경험외화보장여부    za_QSTN_EXPR_FCURR_COVR_YN    choice10
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_QSTN_EXPR_FCURR_COVR_YN') {
          pISuitaVO.ZA_QSTN_EXPR_FCURR_COVR_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_QSTN_EXPR_FCURR_COVR_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문10_설문경험외화기타여부    za_QSTN_EXPR_FCURR_ETC_YN    choice10
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_QSTN_EXPR_FCURR_ETC_YN') {
          pISuitaVO.ZA_QSTN_EXPR_FCURR_ETC_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_QSTN_EXPR_FCURR_ETC_YN = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문11_외화금융상품가입기간    za_QSTN_IVT_EXPR_PRD_CD    choice11
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_QSTN_IVT_EXPR_PRD_CD') {
          pISuitaVO.ZA_QSTN_IVT_EXPR_PRD_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_QSTN_IVT_EXPR_PRD_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문12_원금보전수익율기준    za_QSTN_RSK_PRPNS_CD    choice12
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_QSTN_RSK_PRPNS_CD') {
          pISuitaVO.ZA_QSTN_RSK_PRPNS_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_QSTN_RSK_PRPNS_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문13_손실감내기준    za_QSTN_LOS_LV_CD    choice13
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_QSTN_LOS_LV_CD') {
          pISuitaVO.ZA_QSTN_LOS_LV_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_QSTN_LOS_LV_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
        // 질문14_금융상품이해수준    za_QSTN_APPRE_LV_CD    choice14
        if ( _T_SUITA_CNTNT[i].za_SUITA_QUEST_CNTNT === 'ZA_QSTN_APPRE_LV_CD') {
          pISuitaVO.ZA_QSTN_APPRE_LV_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
          // _pISuitaVO.ZA_QSTN_APPRE_LV_CD = _T_SUITA_CNTNT[i].za_SUITA_ANSW_CNTNT
        }
      } // ~ for (_T_SUITA_CNTNT)

      // 화면매핑----------------------------------------------start
      // 0. 전문가금융소비자여부
      if ( pISuitaVO.exprtFncCspYn === 'Y' ) {
        this.formInput.choice01.push('2') // 01. 전문가금융소비자여부 (1:일반금융소비자, 2:전문금융소비자)
        this.isDisableBtnExprt = false // 전문금융소비자 버튼 활성
        this.c00 = '2'
        this.card00.selected = true
        this.card00.selectedValue = '전문금융소비자'
        this.card00.detailAreaOpen = false
      } else {
        this.formInput.choice01.push('1') // 01. 전문가금융소비자여부 (1:일반금융소비자, 2:전문금융소비자)
        this.isDisableBtnExprt = true // 전문금융소비자 버튼 비활성
        this.c00 = '1'
        this.card00.selected = true
        this.card00.selectedValue = '일반금융소비자'
        this.card00.detailAreaOpen = false
      }
      // 0. 취약금융소비자해당여부
      if ( pISuitaVO.fncCnsmRstcObjYn === 'Y' ) {
        this.formInput.choice02.push('1') // 02. 취약금융소비자해당여부_해당없음
        this.isDisableLeglAgnt = true
      }
      if ( pISuitaVO.highAgeRskYn === 'Y' ) {
        this.formInput.choice02.push('2') // 02. 고연령위험여부_해당(65세이상)
        this.isDisableLeglAgnt = true
      }
      if ( pISuitaVO.miorYn === 'Y' ) {
        this.formInput.choice02.push('3') // 02. 미성년자여부_해당(미성년자)
        this.isDisableLeglAgnt = false
      }
      if ( pISuitaVO.icmpYn === 'Y' ) {
        this.formInput.choice02.push('4') // 02. 금치산자여부_해당(정신적 장애, 기타 등)
        this.isDisableLeglAgnt = false
      }
      // 법정대리인여부
      this.formInput.choice03 = []
      if ( this.isDisableLeglAgnt === false ) {
        if ( pISuitaVO.leglAgntYn === 'Y') {
          this.formInput.choice03.push('1') // 03. 법정대리인여부 (1:예)
          this.c000 = '1'
        } else if ( pISuitaVO.leglAgntYn === 'N') {
          this.formInput.choice03.push('2') // 03. 법정대리인여부 (2:아니오)
          this.c000 = '2'
        }
      }
      // 금융취약계층 선택 텍스트 셋팅
      if(this.formInput.choice02.length > 0) {
        this.card000.selected = true
        this.card000.detailAreaOpen = false
        this.formInput.choice02.forEach(item => {
          if(item === '1') this.card000.selectedValue1 = '해당없음'
          else if(item === '2') this.card000.selectedValue1 = '해당(65세 이상)'
          else if(item === '3') this.card000.selectedValue1 = '해당(미성년자)'
          else if(item === '4') this.card000.selectedValue2 = '해당(정신적 장애 등)'
        })
      }
      // 1. 가입목적 금융상품
      this.formInput.choice1 = !_pISuitaVO.ZA_QSTN_FCURR_ENT_PPS_CD ? { key: '0', label: '선택하세요' } : this.choice1Items[_pISuitaVO.ZA_QSTN_FCURR_ENT_PPS_CD - 1] // 질문01_설문외화가입목적코드
      this.c1 = this.formInput.choice1.key
      this.card1.selected = true
      this.card1.detailAreaOpen = false
      this.card1.selectedValue = this.choice1Items[_pISuitaVO.ZA_QSTN_FCURR_ENT_PPS_CD - 1].label
      // 2. 외화보험 가입목적
      this.formInput.choice2 = !_pISuitaVO.ZA_QSTN_ENT_PPS_CD ? { key: '0', label: '선택하세요' } : this.choice2Items[_pISuitaVO.ZA_QSTN_ENT_PPS_CD - 1] // 질문02_설문가입목적코드
      this.c2 = this.formInput.choice2.key
      this.card2.selected = true
      this.card2.detailAreaOpen = false
      this.card2.selectedValue = this.choice2Items[_pISuitaVO.ZA_QSTN_ENT_PPS_CD - 1].label
      // 3. 외화보험 가입 후 활용계획(복수선택 가능)
      if(_pISuitaVO.ZA_PRCUS_PLN_OVERS_DW_YN === 'Y') this.formInput.choice3.push('1') // 향후 해외 거주할 예정이며, 준비 또는 생활자금 등으로 활용
      if(_pISuitaVO.ZA_PRCUS_PLN_OVERS_TRV_YN === 'Y') this.formInput.choice3.push('2') // 가끔 해외여행경비로 활용
      if(_pISuitaVO.ZA_PRCUS_PLN_LVNG_FD_YN === 'Y') this.formInput.choice3.push('3') // 장기의 미래에 본인 생활자금 또는 유햑계획이 있는 자녀 생활자금등으로 활용
      if(_pISuitaVO.ZA_PRCUS_PLN_IHRT_YN === 'Y') this.formInput.choice3.push('4') // 장기의 미래에 유족 등에게 외화자산 상속등 보장자산으로 활용
      if(_pISuitaVO.ZA_PRCUS_PLN_IVT_YN === 'Y') this.formInput.choice3.push('5') // 안정적 재산증식 수단으로 손실가능성이 없는 외화를 활용
      if(_pISuitaVO.ZA_PRCUS_PLN_COVR_ASET_YN === 'Y') this.formInput.choice3.push('6') // 외화 보장자산 확보를 통한 보장자산 다각화 등 미래 위험분산에 활용
      // 외화보험 가입 후 활용계획(복수선택 가능) 선택 텍스트 셋팅
      if(this.formInput.choice3.length > 0) {
        this.card3.selected = true
        this.card3.detailAreaOpen = false
        this.formInput.choice3.forEach(item => {
          if(item === '1') this.card3.selectedValue1 = this.choice3Items[Number(item) - 1].label
          else if(item === '2') this.card3.selectedValue2 = this.choice3Items[Number(item) - 1].label
          else if(item === '3') this.card3.selectedValue3 = this.choice3Items[Number(item) - 1].label
          else if(item === '4') this.card3.selectedValue4 = this.choice3Items[Number(item) - 1].label
          else if(item === '5') this.card3.selectedValue5 = this.choice3Items[Number(item) - 1].label
          else if(item === '6') this.card3.selectedValue6 = this.choice3Items[Number(item) - 1].label
        })
      }
      // 4. 월평균 소득
      this.formInput.choice4 = !_pISuitaVO.ZA_QSTN_HOSH_INCM_CD ? { key: '0', label: '선택하세요' } : this.choice4Items[_pISuitaVO.ZA_QSTN_HOSH_INCM_CD - 1] // 질문04_설문세대소득코드
      this.c4 = this.formInput.choice4.key
      this.card4.selected = true
      this.card4.detailAreaOpen = false
      this.card4.selectedValue = this.choice4Items[_pISuitaVO.ZA_QSTN_HOSH_INCM_CD - 1].label
      // 5. 월평균 소득대비 보험료 비중
      this.formInput.choice5 = !_pISuitaVO.ZA_QSTN_PRM_RTO_CD ? { key: '0', label: '선택하세요' } : this.choice5Items[_pISuitaVO.ZA_QSTN_PRM_RTO_CD - 1] // 질문05_설문보험료비율코드
      this.c5 = this.formInput.choice5.key
      this.card5.selected = true
      this.card5.detailAreaOpen = false
      this.card5.selectedValue = this.choice5Items[_pISuitaVO.ZA_QSTN_PRM_RTO_CD - 1].label
      // 6. 추가납부 가능금액
      this.formInput.choice6 = !_pISuitaVO.ZA_QSTN_ADPAD_PRM_CD ? { key: '0', label: '선택하세요' } : this.choice6Items[_pISuitaVO.ZA_QSTN_ADPAD_PRM_CD - 1] // 질문06_설문추가납입보험료코드
      this.c6 = this.formInput.choice6.key
      this.card6.selected = true
      this.card6.detailAreaOpen = false
      this.card6.selectedValue = this.choice6Items[_pISuitaVO.ZA_QSTN_ADPAD_PRM_CD - 1].label
      // 7. 보험료 납입가능기간
      this.formInput.choice7 = !_pISuitaVO.ZA_QSTN_PMPRD_CD ? { key: '0', label: '선택하세요' } : this.choice7Items[_pISuitaVO.ZA_QSTN_PMPRD_CD - 1] // 질문07_설문납입기간코드
      this.c7 = this.formInput.choice7.key
      this.card7.selected = true
      this.card7.detailAreaOpen = false
      this.card7.selectedValue = this.choice7Items[_pISuitaVO.ZA_QSTN_PMPRD_CD - 1].label
      // 8. 예상유지기간
      this.formInput.choice8 = !_pISuitaVO.ZA_QSTN_CONT_UKEP_PRD_CD ? { key: '0', label: '선택하세요' } : this.choice8Items[_pISuitaVO.ZA_QSTN_CONT_UKEP_PRD_CD - 1] // 질문08_설문계약유지기간코드
      this.c8 = this.formInput.choice8.key
      this.card8.selected = true
      this.card8.detailAreaOpen = false
      this.card8.selectedValue = this.choice8Items[_pISuitaVO.ZA_QSTN_CONT_UKEP_PRD_CD - 1].label
      // 9. 중도해지 가능성
      this.formInput.choice9 = !_pISuitaVO.ZA_QSTN_MID_RSC_PSBLY_CD ? { key: '0', label: '선택하세요' } : this.choice9Items[_pISuitaVO.ZA_QSTN_MID_RSC_PSBLY_CD - 1] // 질문09_설문중도해지가능성코드    ZA_QSTN_MID_RSC_PSBLY_CD    choice9
      this.c9 = this.formInput.choice9.key
      this.card9.selected = true
      this.card9.detailAreaOpen = false
      this.card9.selectedValue = this.choice9Items[_pISuitaVO.ZA_QSTN_MID_RSC_PSBLY_CD - 1].label
      // 10. 외화금융경험상품(복수선택 가능)
      if(pISuitaVO.ZA_QSTN_EXPR_FCURR_YN === 'Y') this.formInput.choice10.push('1') // 가입경험 없음
      if(pISuitaVO.ZA_QSTN_EXPR_FCURR_DPST_YN === 'Y') this.formInput.choice10.push('2') // 외화예금
      if(pISuitaVO.ZA_QSTN_EXPR_FCURR_FND_YN === 'Y') this.formInput.choice10.push('3') // 외화펀드
      if(pISuitaVO.ZA_QSTN_EXPR_FCURR_STCK_YN === 'Y') this.formInput.choice10.push('4') // 해외주식
      if(pISuitaVO.ZA_QSTN_EXPR_FCURR_SAV_YN === 'Y') this.formInput.choice10.push('5') // 외화저축성보험
      if(pISuitaVO.ZA_QSTN_EXPR_FCURR_COVR_YN === 'Y') this.formInput.choice10.push('6') // 외화보장성보험
      if(pISuitaVO.ZA_QSTN_EXPR_FCURR_ETC_YN === 'Y') this.formInput.choice10.push('7') // 기타
      // 외화금융경험상품 선택 텍스트 셋팅
      if(this.formInput.choice10.length > 0) {
        this.card10.selected = true
        this.card10.detailAreaOpen = false
        this.formInput.choice10.forEach(item => {
          if(item === '1') this.card10.selectedValue1 = this.choice10Items[Number(item) - 1].label
          else if(item === '2') this.card10.selectedValue2 = this.choice10Items[Number(item) - 1].label
          else if(item === '3') this.card10.selectedValue3 = this.choice10Items[Number(item) - 1].label
          else if(item === '4') this.card10.selectedValue4 = this.choice10Items[Number(item) - 1].label
          else if(item === '5') this.card10.selectedValue5 = this.choice10Items[Number(item) - 1].label
          else if(item === '6') this.card10.selectedValue6 = this.choice10Items[Number(item) - 1].label
          else if(item === '7') this.card10.selectedValue7 = this.choice10Items[Number(item) - 1].label
        })
      }
      // 11. 외화금융상품 경험 가입기간
      this.formInput.choice11 = !_pISuitaVO.ZA_QSTN_IVT_EXPR_PRD_CD ? { key: '0', label: '선택하세요' } : this.choice11Items[_pISuitaVO.ZA_QSTN_IVT_EXPR_PRD_CD - 1] // 질문11_외화금융상품가입기간    ZA_QSTN_IVT_EXPR_PRD_CD    choice11
      this.c11 = this.formInput.choice11.key
      this.card11.selected = true
      this.card11.detailAreaOpen = false
      this.card11.selectedValue = this.choice11Items[_pISuitaVO.ZA_QSTN_IVT_EXPR_PRD_CD - 1].label
      // 12. 위험성향
      this.formInput.choice12 = !_pISuitaVO.ZA_QSTN_RSK_PRPNS_CD ? { key: '0', label: '선택하세요' } : this.choice12Items[_pISuitaVO.ZA_QSTN_RSK_PRPNS_CD - 1] // 질문12_원금보전수익율기준    ZA_QSTN_RSK_PRPNS_CD    choice12
      this.c12 = this.formInput.choice12.key
      this.card12.selected = true
      this.card12.detailAreaOpen = false
      this.card12.selectedValue = this.choice12Items[_pISuitaVO.ZA_QSTN_RSK_PRPNS_CD - 1].label
      // 13. 손실감내수준
      this.formInput.choice13 = !_pISuitaVO.ZA_QSTN_LOS_LV_CD ? { key: '0', label: '선택하세요' } : this.choice13Items[_pISuitaVO.ZA_QSTN_LOS_LV_CD - 1] // 질문13_손실감내기준    ZA_QSTN_LOS_LV_CD    choice13
      this.c13 = this.formInput.choice13.key
      this.card13.selected = true
      this.card13.detailAreaOpen = false
      this.card13.selectedValue = this.choice13Items[_pISuitaVO.ZA_QSTN_LOS_LV_CD - 1].label
      // 14. 외화보험 등 금융상품 이해수준
      this.formInput.choice14 = !_pISuitaVO.ZA_QSTN_APPRE_LV_CD ? { key: '0', label: '선택하세요' } : this.choice14Items[_pISuitaVO.ZA_QSTN_APPRE_LV_CD - 1] // 질문14_금융상품이해수준    ZA_QSTN_APPRE_LV_CD    choice14
      this.c14 = this.formInput.choice14.key
      this.card14.selected = true
      this.card14.detailAreaOpen = false
      this.card14.selectedValue = this.choice14Items[_pISuitaVO.ZA_QSTN_APPRE_LV_CD - 1].label
      // 질문01_설문외화가입목적코드     entPpsFncPrdtQstnRslt    choice1 / ZA_QSTN_FCURR_ENT_PPS_CD
      // 질문02_설문가입목적코드     entPpsQstnRslt    choice2 / ZA_QSTN_ENT_PPS_CD
      // 질문03_활용계획해외거주여부    ZA_PRCUS_PLN_OVERS_DW_YN    choice3
      // 질문03_활용계획해외여행여부    ZA_PRCUS_PLN_OVERS_TRV_YN    choice3
      // 질문03_활용계획생활자금여부    ZA_PRCUS_PLN_LVNG_FD_YN    choice3
      // 질문03_활용계획상속여부    ZA_PRCUS_PLN_IHRT_YN    choice3
      // 질문03_활용계획투자여부    ZA_PRCUS_PLN_IVT_YN    choice3
      // 질문03_활용계획보장자산여부    ZA_PRCUS_PLN_COVR_ASET_YN    choice3
      // 질문04_설문세대소득코드    ZA_QSTN_HOSH_INCM_CD    choice4
      // 질문05_설문보험료비율코드     ZA_QSTN_PRM_RTO_CD    choice5
      // 질문06_설문추가납입보험료코드     ZA_QSTN_ADPAD_PRM_CD    choice6
      // 질문07_설문납입기간코드    ZA_QSTN_PMPRD_CD    choice7
      // 질문08_설문계약유지기간코드    ZA_QSTN_CONT_UKEP_PRD_CD    choice8
      // 질문09_설문중도해지가능성코드    ZA_QSTN_MID_RSC_PSBLY_CD    choice9
      // 질문10_설문경험외화여부    ZA_QSTN_EXPR_FCURR_YN    choice10
      // 질문10_설문경험외화예금여부    ZA_QSTN_EXPR_FCURR_DPST_YN    choice10
      // 질문10_설문경험외화펀드여부    ZA_QSTN_EXPR_FCURR_FND_YN    choice10
      // 질문10_설문경험외화주식여부    ZA_QSTN_EXPR_FCURR_STCK_YN    choice10
      // 질문10_설문경험외화저축여부    ZA_QSTN_EXPR_FCURR_SAV_YN    choice10
      // 질문10_설문경험외화보장여부    ZA_QSTN_EXPR_FCURR_COVR_YN    choice10
      // 질문10_설문경험외화기타여부    ZA_QSTN_EXPR_FCURR_ETC_YN    choice10
      // 질문11_외화금융상품가입기간    ZA_QSTN_IVT_EXPR_PRD_CD    choice11
      // 질문12_원금보전수익율기준    ZA_QSTN_RSK_PRPNS_CD    choice12
      // 질문13_손실감내기준    ZA_QSTN_LOS_LV_CD    choice13
      // 질문14_금융상품이해수준    ZA_QSTN_APPRE_LV_CD    choice14
      // SuitaExportVO
      this.SuitaExportVO.exprtFncCspYn = pISuitaVO.exprtFncCspYn ? pISuitaVO.exprtFncCspYn : 'N' // 전문가금융소비자여부
      this.SuitaExportVO.exprtFncCspTypCd = pISuitaVO.exprtFncCspTypCd ? pISuitaVO.exprtFncCspTypCd : '' // 전문가금융소비자유형코드
      this.SuitaExportVO.genFncCspSameTrtmtYn = pISuitaVO.genFncCspSameTrtmtYn ? pISuitaVO.genFncCspSameTrtmtYn : 'N' // 일반금융소비자동일대우여부
      // 금융전문가 정보 배열에 저장
      this.fn_setExportArray()
      // 위험성향분석결과코드로 분석결과 메시지를 설정
      this.fn_prtSutaResult(pISuitaVO)
      window.vue.getStore('progress').dispatch('UPDATE', false)
    },
    /********************************************************************************************
     * 함수 : fn_setExportArray
     * 설명 : 성향 진단 값 배열에 저장
    ********************************************************************************************/
    fn_setExportArray () {
      this.SuitaExportArray = []

      if ( this.SuitaExportVO.exprtFncCspYn === 'Y' ) {
        this.SuitaExportArray.push(this.SuitaExportVO.exprtFncCspTypCd)
        if ( this.SuitaExportVO.genFncCspSameTrtmtYn === 'Y' ) this.SuitaExportArray.push('4')
      }
    },
    /*********************************************************************************************
    * 함수 : fn_prtSutaResult()
    * 설명 : 위험성향분석결과코드로 분석결과 메시지 설정
    *********************************************************************************************/
    fn_prtSutaResult (pISuitaVO) {
      // 가입불가대상판단여부와 위험성향분석결과코드로 분석결과 메시지를 설정한다.
      // this.entDalwObjJdgmtYn = pISuitaVO.entDalwObjJdgmtYn // 가입불가대상판단여부
      // this.rskEftAnlyRslt = pISuitaVO.ZA_SUITA_ANLY_RSLT_CD // 위험영향분석결과(11:위험회피형,12:안정형,13:중립형 ,14:위험선호형, 15:공격형, 99:진단거부)
      if ( pISuitaVO.ZA_AVL_STR_YMD === '' ) {
        pISuitaVO.rskEftAnlyYmd = piCommonUtil.getCurrentDate()
      }
      this.fn_setRskEftAnlyRsltMsg(pISuitaVO) // 위험영향분석결과
      this.MB_entPpsQstnRslt = pISuitaVO.entPpsQstnRslt // 가입목적 변경관련

      if ( this.aprvYn === 'Y' ) { // 승인건만 발행버튼 활성화 (20220118_PJO)
        this.isDisableBtnPlb = false // 발행 버튼
        this.isShowBtnCmplt = true // 선택완료, 진단결과 발행 버튼 show
        this.cmpltBtnTxt = '진단결과 발행'
        this.btnStatus1 = '2' // 1-선택완료, 2-진단결과발행
        this.isDisableBtnCmplt = false // 진단결과 발행 버튼 활성화
        this.isShowBtnOldDataUse = false // 기존정보 사용 버튼
        this.isShowBtnRetry = false // 다시진단하기, 변경 재진단 버튼
      }

      // ASR210701055_적합성 재진단을 위한 시스템 변경 요청 (사랑ON)
      // 당일 진단 승인건이 있는 경우, 재진단 팝업 호출
      if (this.isReSuita && this.aprvYn === 'Y') {
        if(!this.oldDataUseFlag) {
          this.isTSSPI723P = true
          this.isDisableBtnCmplt = true // (4) 재진단사유를 입력하지 않으면 '선택완료' 버튼 비활성화  -> 재진단팝업닫고 재진단사유 값이 있으면 버튼 활성화 됨 (fn_ConfirmTSSPI723P )/(20220118_PJO)
          this.$refs.refDignRsnAlert.open()
        }else {
          setTimeout(()=>{this.oldDataUseFlag = false},700)
          // this.oldDataUseFlag = false
        }
      } else if(!this.isReSuita && this.aprvYn === 'Y') {
        // 당일 진단 승인건이 아닌경우 하단 버튼 '기존정보사용', '입력완료' 활성화 한다.
        if(!this.oldDataUseFlag) {
          this.isShowBtnCmplt = false
          this.isShowBtnOldDataUse = this.isShowBtnRetry = true
          this.isDisableBtnCmplt = false
          // 초기화
          this.rDignYn = '' // 재진단여부
          this.rDignRsnCd = '' // 재진단사유코드
        }else {
          setTimeout(()=>{this.oldDataUseFlag = false},700)
        }
      } else if ( this.strMB_prextSuitaYn === 'N' && this.custInfo.contrRrnBkno.substr(0, 6) !== '000000') {
        // 신규 설문조사의 경우, 지점장 승인요청 메시지를 표시한다.(임시고객일 경우 체크 예외)
        this.isBmngrMsg = true
        this.alertMsg = this.reqConfirmFromBmngrMsg
        this.$refs.refAlert.open()
        this.subBtn3 = this.subBtn10 = true
        this.cmpltBtnTxt = '진단결과 발행'
        this.btnStatus1 = '2' // 1-선택완료, 2-진단결과발행
        if(this.btnStatus1 === '1') this.isDisableBtnCmplt = true // 적합성선택완료 비활성화 (20220118_PJO)
      } else {
        if(this.aprvYn === 'N') {
          this.isBmngrMsg = true
          this.alertMsg = this.reqConfirmFromBmngrMsg
          this.$refs.refAlert.open()
          this.subBtn3 = this.subBtn10 = true
          this.cmpltBtnTxt = '진단결과 발행'
          this.btnStatus1 = '2' // 1-선택완료, 2-진단결과발행
          if(this.btnStatus1 === '1') this.isDisableBtnCmplt = true // 적합성선택완료 비활성화 (20220118_PJO)
        }
      }

      // 법인대표자 정보 저장 처리
      this.fn_setPlanSuitaResntInfo()
    },
    /********************************************************************************************
     * 함수 : fn_setPlanSuitaResntInfo
     * 설명 : 법인대표자 정보 저장 처리
    ********************************************************************************************/
    fn_setPlanSuitaResntInfo () {
      if ( this.zzcusDtlTypCd === '21') {
        this.rltnrInfo.ageatentryVl = this.insrAge // 보험나이
      }

      let rltnrInfo_11 = this.rltnrInfo
      if ( rltnrInfo_11 !== undefined && rltnrInfo_11 !== undefined && rltnrInfo_11.zzcusDtlTypCd === '21') { // 계약자가 단체일경우
        let abdapolicyList = Gaf.getGafObjByRefId('POLICY') // Policy 정보 Array
        if (abdapolicyList !== undefined && abdapolicyList.length > 0) {
          let abdapolicy = abdapolicyList[0] // 현재 Policy 정보
          let abdapolhldr = abdapolicy.abdapolhldr[0]
          let ztpqlCboextAddmAbdapolhldr = abdapolhldr.ztpqlCboextAddmAbdapolhldr

          // 계약자가 단체일경우에는 단체명을 셋팅함
          if (rltnrInfo_11.zzcusDtlTypCd === '21' && !this.$bizUtil.isEmpty(ztpqlCboextAddmAbdapolhldr.zaGNm)) {
            // 법인대표자명
            ztpqlCboextAddmAbdapolhldr.zaResntNm = this.custInfo.custNm // this.contrNm
            // 법인대표자주민번호
            ztpqlCboextAddmAbdapolhldr.zaResntRrnId = this.custInfo.contrRrnFrno + this.custInfo.contrRrnBkno // this.contrRrnFrno + this.contrRrnBkno
            // 법인대표자 Agent File ID
            ztpqlCboextAddmAbdapolhldr.zaRentAgtFileId = this.custInfo.chnlCustId
          }
        }
      }
    },
    /********************************************************************************************
     * 함수 : fn_setData
     * 설명 : 금융 취약 계층 답변, 설문지에 작성한 답안 정보 설정
    ********************************************************************************************/
    fn_setData () {
      this.fncCnsm.fncCnsmRstcObjYn = 'N'
      this.fncCnsm.highAgeRskYn = 'N'
      this.fncCnsm.miorYn = 'N'
      this.fncCnsm.icmpYn = 'N'

      for ( let i = 0; i < this.formInput.choice02.length; i++ ) {
        if ( this.formInput.choice02[i] === '1' ) {
          this.fncCnsm.fncCnsmRstcObjYn = 'Y' // 취약금융소비자해당여부
        } else if ( this.formInput.choice02[i] === '2' ) {
          this.fncCnsm.highAgeRskYn = 'Y' // 고연령위험여부
        } else if ( this.formInput.choice02[i] === '3' ) {
          this.fncCnsm.miorYn = 'Y' // 미성년자여부
        } else if ( this.formInput.choice02[i] === '4' ) {
          this.fncCnsm.icmpYn = 'Y' // 금치산자여부
        } else {
          console.log('Out of Value')
        }
      }
    },
    /*****************************************************************************************
     * 함수 : fn_setPRCUS_PLN
     * 설명 : 3번  활용 계획
     *****************************************************************************************/
    fn_setPRCUS_PLN () {
      this.PRCUS_PLN.ZA_PRCUS_PLN_OVERS_DW_YN = 'N'// 1 활용계획해외거주여부
      this.PRCUS_PLN.ZA_PRCUS_PLN_OVERS_TRV_YN = 'N'// 2 활용계획해외여행여부
      this.PRCUS_PLN.ZA_PRCUS_PLN_LVNG_FD_YN = 'N'// 3 활용계획생활자금여부
      this.PRCUS_PLN.ZA_PRCUS_PLN_IHRT_YN = 'N'// 4 활용계획상속여부
      this.PRCUS_PLN.ZA_PRCUS_PLN_IVT_YN = 'N'// 5 활용계획투자여부
      this.PRCUS_PLN.ZA_PRCUS_PLN_COVR_ASET_YN = 'N' // 6 활용계획보장자산여부

      for ( let i = 0; i < this.formInput.choice3.length; i++ ) {
        if ( this.formInput.choice3[i] === '1' ) {
          this.PRCUS_PLN.ZA_PRCUS_PLN_OVERS_DW_YN = 'Y'
        } else if ( this.formInput.choice3[i] === '2' ) {
          this.PRCUS_PLN.ZA_PRCUS_PLN_OVERS_TRV_YN = 'Y'
        } else if ( this.formInput.choice3[i] === '3' ) {
          this.PRCUS_PLN.ZA_PRCUS_PLN_LVNG_FD_YN = 'Y'
        } else if ( this.formInput.choice3[i] === '4' ) {
          this.PRCUS_PLN.ZA_PRCUS_PLN_IHRT_YN = 'Y'
        } else if ( this.formInput.choice3[i] === '5' ) {
          this.PRCUS_PLN.ZA_PRCUS_PLN_IVT_YN = 'Y'
        } else if ( this.formInput.choice3[i] === '6' ) {
          this.PRCUS_PLN.ZA_PRCUS_PLN_COVR_ASET_YN = 'Y'
        }
      }
    },
    /*****************************************************************************************
     * 함수 : fn_setQSTN_EXPR()
     * 설명 : 10번 금융상품 가입 경험
     *****************************************************************************************/
    fn_setQSTN_EXPR () {
      this.QSTN_EXPR.ZA_QSTN_EXPR_FCURR_YN = 'N' // 1 가입경험 없음
      this.QSTN_EXPR.ZA_QSTN_EXPR_FCURR_DPST_YN = 'N' // 2 외화예금
      this.QSTN_EXPR.ZA_QSTN_EXPR_FCURR_FND_YN = 'N' // 3 외화펀드
      this.QSTN_EXPR.ZA_QSTN_EXPR_FCURR_STCK_YN = 'N' // 4 해외주식
      this.QSTN_EXPR.ZA_QSTN_EXPR_FCURR_SAV_YN = 'N' // 5 외화저축성보험
      this.QSTN_EXPR.ZA_QSTN_EXPR_FCURR_COVR_YN = 'N' // 6 외화보장성보험
      this.QSTN_EXPR.ZA_QSTN_EXPR_FCURR_ETC_YN = 'N' // 7 기타

      for ( let i = 0; i < this.formInput.choice10.length; i++ ) {
        if ( this.formInput.choice10[i] === '1' ) {
          this.QSTN_EXPR.ZA_QSTN_EXPR_FCURR_YN = 'Y'
        } else if ( this.formInput.choice10[i] === '2' ) {
          this.QSTN_EXPR.ZA_QSTN_EXPR_FCURR_DPST_YN = 'Y'
        } else if ( this.formInput.choice10[i] === '3' ) {
          this.QSTN_EXPR.ZA_QSTN_EXPR_FCURR_FND_YN = 'Y'
        } else if ( this.formInput.choice10[i] === '4' ) {
          this.QSTN_EXPR.ZA_QSTN_EXPR_FCURR_STCK_YN = 'Y'
        } else if ( this.formInput.choice10[i] === '5' ) {
          this.QSTN_EXPR.ZA_QSTN_EXPR_FCURR_SAV_YN = 'Y'
        } else if ( this.formInput.choice10[i] === '6' ) {
          this.QSTN_EXPR.ZA_QSTN_EXPR_FCURR_COVR_YN = 'Y'
        } else if ( this.formInput.choice10[i] === '7' ) {
          this.QSTN_EXPR.ZA_QSTN_EXPR_FCURR_ETC_YN = 'Y'
        }
      }
    },
    /********************************************************************************************
     * 함수 : fn_setSuitaAnswerData
     * 설명 : 적합성 입력 완료 -> 적합성 심사 요청을 하기위한 입력값 VO(dcSuitaCnsntVO)에 넣기
    ********************************************************************************************/
    fn_setSuitaAnswerData () {
      this.fn_setData() // 취약금융소비자 / fncCnsm
      // 외화
      this.fn_setPRCUS_PLN() // 3.활용 계획 / PRCUS_PLN
      this.fn_setQSTN_EXPR() // 10.금융상품 가입 경험 / QSTN_EXPR

      this.dcSuitaCnsntVO = {
        // 계약정보
        R_DIGN_YN: this.rDignYn, // 재진단여부
        R_DIGN_RSN_CD: this.rDignRsnCd, // 재진단사유코드/ 01:사실관계 착오,02: 단순오기

        // T_SUITA_CNTNT 질문답변 리스트
        // 전문금융소비자 answerList[0]
        // 금융전문가
        EXPRT_FNC_CSP_YN: this.formInput.choice01[0] === '1' ? 'N' : 'Y', // 01. 전문가금융소비자여부 (1:일반, 2:전문)
        EXPRT_FNC_CSP_TYP_CD: this.formInput.choice01[0] === '1' ? '' : this.SuitaExportVO.exprtFncCspTypCd, // 01. 전문가금융소비자유형코드
        GEN_FNC_CSP_SAME_TRTMT_YN: this.formInput.choice01[0] === '1' ? '' : this.SuitaExportVO.genFncCspSameTrtmtYn, // 01. 일반금융소비자동일대우여부

        // 취약금융소비자
        FNC_CNSM_RSTC_OBJ_YN: this.fncCnsm.fncCnsmRstcObjYn, // 02. 취약금융소비자해당여부
        QSTN_HIGH_AGE_RSK_YN: this.fncCnsm.highAgeRskYn, // 02. 고연령위험여부
        MIOR_YN: this.fncCnsm.miorYn, // 02. 미성년자여부
        QSTN_ICMP_YN: this.fncCnsm.icmpYn, // 02. 정신적 장애, 기타 등(금치산자여부)
        LEGL_AGNT_YN: this.fncCnsm.leglAgntYn === '1' ? 'Y' : this.fncCnsm.leglAgntYn === '2' ? 'N' : '', // 02. 법적대리인여부

        // 질문1,2
        QSTN_FCURR_ENT_PPS_CD: this.formInput.choice1.key, // 가입목적금융상품설문결과
        QSTN_ENT_PPS_CD: this.formInput.choice2.key, // 가입목적설문결과
        // 질문3 this.answerList[4]
        PRCUS_PLN_OVERS_DW_YN: this.PRCUS_PLN.ZA_PRCUS_PLN_OVERS_DW_YN, // 1 활용계획해외거주여부
        PRCUS_PLN_OVERS_TRV_YN: this.PRCUS_PLN.ZA_PRCUS_PLN_OVERS_TRV_YN, // 2 활용계획해외여행여부
        PRCUS_PLN_LVNG_FD_YN: this.PRCUS_PLN.ZA_PRCUS_PLN_LVNG_FD_YN, // 3 활용계획생활자금여부
        PRCUS_PLN_IHRT_YN: this.PRCUS_PLN.ZA_PRCUS_PLN_IHRT_YN, // 4 활용계획상속여부
        PRCUS_PLN_IVT_YN: this.PRCUS_PLN.ZA_PRCUS_PLN_IVT_YN, // 5 활용계획투자여부
        PRCUS_PLN_COVR_ASET_YN: this.PRCUS_PLN.ZA_PRCUS_PLN_COVR_ASET_YN, // 6 활용계획보장자산여부
        // --
        QSTN_HOSH_INCM_CD: this.formInput.choice4.key, // 질문4 월평균소득설문결과
        QSTN_PRM_RTO_CD: this.formInput.choice5.key, // 질문5 순자산금액설문결과
        QSTN_ADPAD_PRM_CD: this.formInput.choice6.key, // 질문6 추가납입보험료설문결과
        QSTN_PMPRD_CD: this.formInput.choice7.key, // 질문7 납입기간설문결과
        QSTN_CONT_UKEP_PRD_CD: this.formInput.choice8.key, // 질문8 유지기간설문결과
        QSTN_MID_RSC_PSBLY_CD: this.formInput.choice9.key, // 질문9  중도해지가능성설문결과
        // 질문 10.가입경험
        QSTN_EXPR_FCURR_YN: this.QSTN_EXPR.ZA_QSTN_EXPR_FCURR_YN, // 1 가입경험 없음
        QSTN_EXPR_FCURR_DPST_YN: this.QSTN_EXPR.ZA_QSTN_EXPR_FCURR_DPST_YN, // 2 외화예금
        QSTN_EXPR_FCURR_FND_YN: this.QSTN_EXPR.ZA_QSTN_EXPR_FCURR_FND_YN, // 3 외화펀드
        QSTN_EXPR_FCURR_STCK_YN: this.QSTN_EXPR.ZA_QSTN_EXPR_FCURR_STCK_YN, // 4 해외주식
        QSTN_EXPR_FCURR_SAV_YN: this.QSTN_EXPR.ZA_QSTN_EXPR_FCURR_SAV_YN, // 5 외화저축성보험
        QSTN_EXPR_FCURR_COVR_YN: this.QSTN_EXPR.ZA_QSTN_EXPR_FCURR_COVR_YN, // 6 외화보장성보험
        QSTN_EXPR_FCURR_ETC_YN: this.QSTN_EXPR.ZA_QSTN_EXPR_FCURR_ETC_YN, // 7 기타
        // 질문11~14
        QSTN_IVT_EXPR_PRD_CD: this.formInput.choice11.key, // 질문11 가입기간
        QSTN_RSK_PRPNS_CD: this.formInput.choice12.key, // 질문12 위험성향
        QSTN_LOS_LV_CD: this.formInput.choice13.key, // 질문13 설문손실수준코드
        QSTN_APPRE_LV_CD: this.formInput.choice14.key // 질문14 외화금융상품 이해수준
      }
      // this.MB_entPpsQstnRslt = this.formInput.choice2.key // 가입목적설문결과 // 외화,확인필요
      // this.MB_entDalwObjJdgmt = 'N' // 가입불가대상판 // 외화,확인필요

      console.log('dcSuitaCnsntVO>>>>>>>>', this.dcSuitaCnsntVO)
    },
    /***********************************************************************
     * 함수 : fn_RequestSuitaDign
     * 설명 : 분석결과에 대한 진단 요청 - ( S2 ) 처리
     ************************************************************************/
    fn_RequestSuitaDign () {
      // ===========================
      // let currDateTime = piCommonUtil.getCurrentDateTime() // 처리일자
      // console.log('currData', currDateTime)
      // // 현재 날짜와 시간 값 설정
      // let currDate = currDateTime.substr(0, 8)
      // let currTime = currDateTime.substr(8, 12)
      // REQ  1     0    ZA_INFO_PRCUS_CNSNT_ID         정보활용동의ID             CHAR      20      결과값
      // REQ  2     2    ZA_CHNL_ID                     컨설턴트번호               CHAR      10      컨설턴트 번호
      // REQ  3     3    NAME_LAST                      계약자명                  CHAR      60      계약자명
      // REQ  4     4    IDNUMBER                       계약자주민등록번호            CHAR      13      계약자주민등록번호
      // REQ  5     5    ZA_SUITA_CNSNT_TYP_CD          적합성동의유형코드            CHAR      2      적합성동의유형코드 (02 외화적합성)
      // REQ  6     0    ZA_QSTN_PRDT_RSLT_CD           설문상품결과코드             CHAR      1      결과값 (1  부적합,2 저축형,3 연금형,4 보장형,9 진단거부)
      // REQ  7     0    ZA_SUITA_ANLY_RSLT_CD          적합성분석결과코드            CHAR      2      결과값(21 외화회피형 22 외화안정형 23 외화중립형 24 외화 선호형 25 외화 공격형)
      // REQ  8     8    ZA_R_DIGN_YN                   재진단여부                 CHAR      1      재진단여부 ( Y, N)
      // REQ  9     9    ZA_R_DIGN_RSN_CD               재진단사유코드              CHAR      2      재진단사유코드 (01:사실관계 착오,02: 단순오기)
      // REQ  10   10    CHUSR                          오브젝트를 변경한 최종 사용자    CHAR      10      컨설턴트 번호와 동일값
      // REQ  11   11    CHDAT                          오브젝트 최종 변경일          DATS      8      요청일: YYYYMMDD
      // REQ  12   12    CHTIM                          오브젝트 최종 변경 시간        TIMS      6      요청시간: HHMMSS
      // REQ  13   13    ZA_TRT_DEPT_CD                 처리부서코드               NUMC      8      처리부서코드 (컨설턴트 소속 부서코드 ex:00001519)
      // REQ  14   14    ZA_BUSN_TRT_PATH_CD            업무처리경로코드             CHAR      4      업무처리경로 / 2220 (태블릿_사랑온고객관리)
      // REQ  15   15    ZA_TRT_SYSTM_PATH_CD           처리시스템경로코드            CHAR      4      시스템경로코드 / 22(태블릿)
      // REQ  16   16    ZA_TRT_SYSTM_PATH_DTL_VAL      처리시스템경로상세값           CHAR      100    TSSPI780M  (외화보험적합성 태블릿) TSSPI780P (외화보험적합성 종이류)
      // REQ  17   17    T_SUITA_CNTNT                  적합성설문내역리스트           TABLE            적합성설문내역리스트
      // REQ  18   18    ZA_SUITA_QUEST_CNTNT           적합성질문내용              CHAR      30     적합성질문내용
      // REQ  19   19    ZA_SUITA_ANSW_CNTNT            적합성답변내용              CHAR      2      적합성답변내용
      // REQ  20   0     TYPE                           처리결과타입               CHAR      1
      // REQ  21   0     MESSAGE                        처리결과내용               CHAR      220
      var inputData = {
        ZA_INFO_PRCUS_CNSNT_ID: '', // 1 정보활용동의ID / (체크시에는 id 없다)
        ZA_CHNL_ID: this.headerVO.userId, // 2 컨설턴트번호
        NAME_LAST: this.custInfo.custNm, // 3 계약자명
        IDNUMBER: this.custInfo.knb, // 4 계약자주민등록번호 // this.custInfo.contrRrnFrno + this.custInfo.contrRrnBkno,
        ZA_SUITA_CNSNT_TYP_CD: '02', // 5 적합성동의유형코드 (02 외화적합성)
        ZA_QSTN_PRDT_RSLT_CD: '', // 6 설문상품결과코드 / 결과값 (1  부적합,2 저축형,3 연금형,4 보장형,9 진단거부)
        ZA_SUITA_ANLY_RSLT_CD: '', // 7 적합성분석결과코드 / 결과값(21 외화회피형 22 외화안정형 23 외화중립형 24 외화 선호형 25 외화 공격형)
        ZA_R_DIGN_YN: this.rDignYn, // 8 재진단여부 / ( Y, N)
        ZA_R_DIGN_RSN_CD: this.rDignRsnCd, // 9 재진단사유코드(01:사실관계 착오,02: 단순오기)
        CHUSR: this.headerVO.userId, // 10 컨설턴트번호
        CHDAT: moment(new Date().toISOString()).format('YYYYMMDD').toString(), // 11 요청일: YYYYMMDD
        CHTIM: moment(new Date().toISOString()).format('HHmmss').toString(), // 12 요청시간: HHMMSS
        ZA_TRT_DEPT_CD: this.headerVO.trtFofOrgNo, // 13 처리부서코드 (컨설턴트 소속 부서코드 ex:00001519)
        ZA_BUSN_TRT_PATH_CD: '2220', // 14 업무처리경로 / 2220 (태블릿_사랑온고객관리)
        ZA_TRT_SYSTM_PATH_CD: '22', // 15 시스템경로코드 / 22(태블릿)
        ZA_TRT_SYSTM_PATH_DTL_VAL: 'TSSPI780P', // 16 처리시스템경로상세값  / TSSPI780M  (외화보험적합성 태블릿) TSSPI780P (외화보험적합성 종이류)
        T_SUITA_CNTNT: this.fn_make_SUITA_CNTNT(), // ★★★★★★★ 17 적합성설문내역리스트 TABLE
        // REQ  18   18    ZA_SUITA_QUEST_CNTNT 적합성질문내용
        // REQ  19   19    ZA_SUITA_ANSW_CNTNT 적합성답변내용
        TYPE: '', // 20 처리결과타입 20
        MESSAGE: '' // 21 처리결과내용
      }
      // =================================
      console.log('inputData:\n' + JSON.stringify(inputData))
      window.vue.getStore('progress').dispatch('UPDATE', true)
      piCommonUtil.invoke('UF1MDI0390', inputData, this.fn_RequestSuitaDignHandler, null, this.fn_RequestSuitaDignHandler, this, this)
    },
    /************************************************************************************
    함수 : SUITA_CNTNT  만들기(33개)
    설명 : 취약금융소비자,전문금융소비자, 질문14개  IF 항목값으로 세팅
    ************************************************************************************/
    fn_make_SUITA_CNTNT () {
      // ZA_SUITA_QUEST_CNTNT 적합성질문내용
      // ZA_SUITA_ANSW_CNTNT 적합성답변내용
      let _T_SUITA_CNTNT = []
      // 전문금융소비자 여부
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_EXPRT_FNC_CSP_YN', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.EXPRT_FNC_CSP_YN}) // 전문가금융소비자여부
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_EXPRT_FNC_CSP_TYP_CD', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.EXPRT_FNC_CSP_TYP_CD}) // 전문가금융소비자유형코드
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_GEN_FNC_CSP_SAME_TRTMT_YN', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.GEN_FNC_CSP_SAME_TRTMT_YN}) // 전문가금융소비자유형코드
      // 취약금융소비자
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_FNC_CNSM_RSTC_OBJ_YN', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.FNC_CNSM_RSTC_OBJ_YN}) // 금융소비제한대상여부
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_QSTN_HIGH_AGE_RSK_YN', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.QSTN_HIGH_AGE_RSK_YN}) // 설문고연령위험여부
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_MIOR_YN', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.MIOR_YN}) // 미성년자여부
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_QSTN_ICMP_YN', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.QSTN_ICMP_YN}) // 금치산자여부
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_LEGL_AGNT_YN', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.LEGL_AGNT_YN}) // 법적대리인여부
      // 01~02
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_QSTN_FCURR_ENT_PPS_CD', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.QSTN_FCURR_ENT_PPS_CD}) // 질문01 가입목적금융상품설문결과
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_QSTN_ENT_PPS_CD', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.QSTN_ENT_PPS_CD}) // 질문02 가입목적설문결과
      // 질문 03  활용계획
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_PRCUS_PLN_OVERS_DW_YN', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.PRCUS_PLN_OVERS_DW_YN}) // 활용계획해외거주여부
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_PRCUS_PLN_OVERS_TRV_YN', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.PRCUS_PLN_OVERS_TRV_YN}) // 활용계획해외여행여부
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_PRCUS_PLN_LVNG_FD_YN', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.PRCUS_PLN_LVNG_FD_YN}) // 활용계획생활자금여부
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_PRCUS_PLN_IHRT_YN', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.PRCUS_PLN_IHRT_YN}) // 활용계획상속여부
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_PRCUS_PLN_IVT_YN', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.PRCUS_PLN_IVT_YN}) // 활용계획투자여부
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_PRCUS_PLN_COVR_ASET_YN', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.PRCUS_PLN_COVR_ASET_YN}) // 활용계획보장자산여부
      // 05~09
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_QSTN_HOSH_INCM_CD', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.QSTN_HOSH_INCM_CD}) // 질문04 월평균소득설문결과
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_QSTN_PRM_RTO_CD', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.QSTN_PRM_RTO_CD}) // 질문05 소득대비보험료
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_QSTN_ADPAD_PRM_CD', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.QSTN_ADPAD_PRM_CD}) // 질문06 추가납입보험료
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_QSTN_PMPRD_CD', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.QSTN_PMPRD_CD}) // 질문07 납입기간
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_QSTN_CONT_UKEP_PRD_CD', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.QSTN_CONT_UKEP_PRD_CD}) // 질문08 추가납입보험료설문결과
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_QSTN_MID_RSC_PSBLY_CD', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.QSTN_MID_RSC_PSBLY_CD}) // 질문09 추가납입보험료설문결과
      // 질문 10  가입경험
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_QSTN_EXPR_FCURR_YN', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.QSTN_EXPR_FCURR_YN}) // 1 가입경험 없음
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_QSTN_EXPR_FCURR_DPST_YN', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.QSTN_EXPR_FCURR_DPST_YN}) // 2 외화예금
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_QSTN_EXPR_FCURR_FND_YN', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.QSTN_EXPR_FCURR_FND_YN}) // 3 외화펀드
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_QSTN_EXPR_FCURR_STCK_YN', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.QSTN_EXPR_FCURR_STCK_YN}) // 4 해외주식
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_QSTN_EXPR_FCURR_SAV_YN', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.QSTN_EXPR_FCURR_SAV_YN}) // 5 외화저축성보험
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_QSTN_EXPR_FCURR_COVR_YN', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.QSTN_EXPR_FCURR_COVR_YN}) // 6 외화보장성보험
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_QSTN_EXPR_FCURR_ETC_YN', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.QSTN_EXPR_FCURR_ETC_YN}) // 7 기타
      // 11~14
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_QSTN_IVT_EXPR_PRD_CD', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.QSTN_IVT_EXPR_PRD_CD}) // 질문11 가입기간
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_QSTN_RSK_PRPNS_CD', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.QSTN_RSK_PRPNS_CD}) // 질문12 위험성향
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_QSTN_LOS_LV_CD', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.QSTN_LOS_LV_CD}) // 질문13 설문손실수준코드
      _T_SUITA_CNTNT.push({ZA_SUITA_QUEST_CNTNT: 'ZA_QSTN_APPRE_LV_CD', ZA_SUITA_ANSW_CNTNT: this.dcSuitaCnsntVO.QSTN_APPRE_LV_CD}) // 질문14 외화금융상품 이해수준
      return _T_SUITA_CNTNT
    },
    /********************************************************************************************
     * 함수 : fn_RequestSuitaDignHandler
     * 설명 : 등록요청 응답 처리 Handler
    ********************************************************************************************/
    fn_RequestSuitaDignHandler (_pISuitaVO) {
      this.isSuitaExpire = false

      let pISuitaVO = _pISuitaVO // ★수정필요 (pISuitaVO 기존vo 기준으로 세팅)
      pISuitaVO.cnsltNo = _pISuitaVO.za_CHNL_ID

      pISuitaVO.ZA_SUITA_CNSNT_TYP_CD = _pISuitaVO.za_SUITA_CNSNT_TYP_CD // 5     ZA_SUITA_CNSNT_TYP_CD    적합성동의유형코드     02 외화적합성/ 조회시 필수값
      pISuitaVO.ZA_AVL_STR_YMD = _pISuitaVO.za_AVL_STR_YMD // 6     ZA_AVL_STR_YMD    유효시작일자
      pISuitaVO.ZA_AVL_END_YMD = _pISuitaVO.za_AVL_END_YMD // 7     ZA_AVL_END_YMD    유효종료일자
      pISuitaVO.ZA_INFO_PRCUS_CNSNT_STAT_CD = _pISuitaVO.za_INFO_PRCUS_CNSNT_STAT_CD // 8     ZA_INFO_PRCUS_CNSNT_STAT_CD    정보활용동의상태코드
      pISuitaVO.ZA_APL_FOF_DEPT_CD = _pISuitaVO.za_APL_FOF_DEPT_CD // 9     ZA_APL_FOF_DEPT_CD    신청지점부서코드
      pISuitaVO.ZA_APL_DTM = _pISuitaVO.za_APL_DTM // 11    ZA_APL_DTM    신청일시
      pISuitaVO.ZA_APRVR_ENO = _pISuitaVO.za_APRVR_ENO // 12    ZA_APRVR_ENO    승인자사번
      pISuitaVO.ZA_APRV_DTM = _pISuitaVO.za_APRV_DTM // 13    ZA_APRV_DTM    승인일시
      pISuitaVO.ZA_CANC_TRTR_ENO = _pISuitaVO.za_CANC_TRTR_ENO // 14    ZA_CANC_TRTR_ENO    취소처리자사번
      pISuitaVO.ZA_CANC_DTM = _pISuitaVO.za_CANC_DTM// 15    ZA_CANC_DTM    취소일시
      pISuitaVO.ZA_INFO_PRCUS_CNSNT_CANC_RSN = _pISuitaVO.za_INFO_PRCUS_CNSNT_CANC_RSN // 16    ZA_INFO_PRCUS_CNSNT_CANC_RSN    정보활용동의취소사유
      pISuitaVO.ZA_QSTN_PRDT_RSLT_CD = _pISuitaVO.za_QSTN_PRDT_RSLT_CD // 17    ZA_QSTN_PRDT_RSLT_CD    설문상품결과코드
      pISuitaVO.ZA_SUITA_ANLY_RSLT_CD = _pISuitaVO.za_SUITA_ANLY_RSLT_CD // 18    ZA_SUITA_ANLY_RSLT_CD    적합성분석결과코드
      pISuitaVO.ZA_R_DIGN_YN = _pISuitaVO.za_R_DIGN_YN // 19    ZA_R_DIGN_YN    재진단여부
      pISuitaVO.ZA_R_DIGN_RSN_CD = _pISuitaVO.za_R_DIGN_RSN_CD // 20    ZA_R_DIGN_RSN_CD    재진단사유코드

      this.fn_setRskEftAnlyRsltMsg( pISuitaVO ) // 위험영향분석결과
      this.MB_entPpsQstnRslt = pISuitaVO.entPpsQstnRslt

      if ( pISuitaVO.ZA_INFO_PRCUS_CNSNT_STAT_CD !== '02') { // 미승인건 버튼 비활성화
        this.isDisableBtnCmplt = true // 적합성 선택완료 버튼 비활성화
        this.isDisableBtnPlb = true // 진단결과 발행 버튼 비활성화
        this.aprvYn = 'N'
      }
      // 신규 설문조사의 경우, 지점장 승인요청 메시지를 표시한다.(임시고객일 경우 체크 예외)
      let l_contrRrnBkno = this.contrRrnBkno
      console.log('l_contrRrnBkno', l_contrRrnBkno)
      if ( (this.strMB_prextSuitaYn === 'N' || (this.isReSuita && this.aprvYn === 'N')) && this.custInfo.contrRrnBkno.substr(1, 6) !== '000000' ) {
        this.isBmngrMsg = true
        this.alertMsg = this.reqConfirmFromBmngrMsg
        this.$refs.refAlert.open()
        this.subBtn3 = this.subBtn10 = true
        this.cmpltBtnTxt = '진단결과 발행'
        this.btnStatus1 = '2' // 1-선택완료, 2-진단결과발행
      }

      // 법인대표자 정보 저장 처리
      this.fn_setPlanSuitaResntInfo()

      window.vue.getStore('progress').dispatch('UPDATE', false)
    },
    /********************************************************************************************
    * 함수 : fn_setRskEftAnlyRsltMsg()
    * 설명 : 외화_위험성향진단결과를 설정
    ********************************************************************************************/
    fn_setRskEftAnlyRsltMsg (pISuitaVO) {
      // ZA_QSTN_PRDT_RSLT_CD 설문상품결과코드 (1  부적합,2 저축형,3 연금형,4 보장형)
      // ZA_SUITA_ANLY_RSLT_CD 적합성분석결과코드 (21 외화회피형 22 외화안정형 23 외화중립형 24 외화선호형 25 외화공격형)
      // ZA_R_DIGN_YN 재진단여부 ( Y, N)
      // ZA_R_DIGN_RSN_CD 재진단사유코드 (01:사실관계 착오,02: 단순오기)
      let rskEftAnlyRslt = pISuitaVO.ZA_SUITA_ANLY_RSLT_CD

      // 투자(특별계정펀드) 성향
      // ZA_SUITA_ANLY_RSLT_CD 적합성분석결과코드 (21 외화회피형 22 외화안정형 23 외화중립형 24 외화선호형 25 외화공격형)
      if (rskEftAnlyRslt === '21') {
        this.lbRskEftAnlyRslt = '외화회피형'
      } else if (rskEftAnlyRslt === '22') {
        this.lbRskEftAnlyRslt = '외화안정형'
      } else if (rskEftAnlyRslt === '23') {
        this.lbRskEftAnlyRslt = '외화중립형'
      } else if (rskEftAnlyRslt === '24') {
        this.lbRskEftAnlyRslt = '외화선호형'
      } else if (rskEftAnlyRslt === '25') {
        this.lbRskEftAnlyRslt = '외화공격형'
      }

      // 보험상품 성향
      // ZA_QSTN_PRDT_RSLT_CD 설문상품결과코드 (1  부적합,2 저축형,3 연금형,4 보장형)
      let qstnPrdtRsltCd = pISuitaVO.ZA_QSTN_PRDT_RSLT_CD
      if (qstnPrdtRsltCd === '1') {
        this.lbInsrAnlyRslt = '부적합'
      } else if (qstnPrdtRsltCd === '2') {
        this.lbInsrAnlyRslt = '저축형'
      } else if (qstnPrdtRsltCd === '3') {
        this.lbInsrAnlyRslt = '연금형'
      } else if (qstnPrdtRsltCd === '4') {
        this.lbInsrAnlyRslt = '보장형'
      }
      let _toDay = piCommonUtil.getCurrentDate()
      let todayDateAfter3M = DateUtil.addDate(_toDay, +90) // 91일이후날짜(91일이 맞지만, +1일이되서 계산됨/만료일자계산은 90일로 세팅함) 2021.11_PJO

      // ZA_AVL_STR_YMD 유효시작일자
      // ZA_AVL_END_YMD 유효종료일자
      let _ZA_AVL_END_YMD = ( pISuitaVO.ZA_AVL_END_YMD === '' || pISuitaVO.ZA_AVL_END_YMD === undefined ) ? todayDateAfter3M : pISuitaVO.ZA_AVL_END_YMD
      // 보험상품적합성 결과 메세지 - 날짜
      this.lbInsrAnlyRslt_Date = '( ~ ' + DateUtil.fn_DateFormat(_ZA_AVL_END_YMD, 'yyyy-MM-dd') + ')'
      // 특별계정펀드 적합성 - 만료 날짜 표시
      this.lbRskEftAnlyRslt_Date = '( ~ ' + DateUtil.fn_DateFormat(_ZA_AVL_END_YMD, 'yyyy-MM-dd') + ')'

      this.fn_setExportArray()
      // 승인/미승인 라벨 출력
      this.fn_setAprvLabel(pISuitaVO)
    },
    /********************************************************************************************
     * 함수 : fn_setAprvLabel
     * 설명 : 적합성 승인/미승인 텍스트 설정
    ********************************************************************************************/
    fn_setAprvLabel ( pISuitaVO ) {
      if ( this.aprvYn === 'Y') {
        this.aprvMsg = '승인'
        this.isDisableIns = false
      } else {
        this.aprvYn = 'N' // '' 인경우 보완 (20220118_PJO)
        this.aprvMsg = '미승인'
        this.isDisableIns = true
      }

      // 진단결과 노출
      this.card0.result = true
      this.card0.detailAreaOpen = true
    },
    /********************************************************************************************
     * 함수 : fn_cbRskEftAnlyResConfirm
     * 설명 : 위험성향진단 예약발행 ( P2 ) - 전처리
    ********************************************************************************************/
    fn_cbRskEftAnlyResConfirm () {
      if ( this.aprvYn === 'Y') {
        this.isRskEftAnlyRes = true // 위험성향진단 예약발행 \n\n 인쇄를 하시겠습니까?
        this.alertMsg = this.RskEftAnlyResMsg
        this.alertBtnLb1 = '취소'
        this.alertBtnLb2 = '확인'
        this.$refs.refConfirm.open()
      } else {
        // "위험성향 진단 질문지 승인후 발행가능합니다.(진단일 + 3개월)"
        this.isRskEftAnlyAlert = true
        this.alertMsg = this.RskEftAnlyAlertMsg
        this.$refs.refAlert.open()
      }
    },
    /********************************************************************************************
     * 함수 : fn_RequestpblSuita
     * 설명 : 위험성향진단 발행 (P2)
    ********************************************************************************************/
    fn_RequestpblSuita () {
      var inputData = {
        dgrstReqCd: 'A', // 진단결과요청코드 - 하드코딩
        tlgmAddmYn: ' ', // 전문추가여부 - 보고서전문추가 일때만 Y, 나머지는 공백  - 하드코딩
        srvcInflPathCd: '6', // 서비스유입경로코드  - 하드코딩
        suitaWcnstId: this.suitaDignId, // 적합성동의서ID
        reqHedrCntntVO: {
          cnsltNo: this.headerVO.userId, // 컨설턴트번호
          busnTrtPathCd: '2222', // 업무처리경로코드  - 하드코딩
          trtSystmPathCd: '22', // 처리시스템경로코드  - 하드코딩
          trtSystmPathCdVal: '사랑온 적합성 발행', // 처리시스템경로코드값  - 하드코딩
          trtTmd: moment(new Date().toISOString()).format('YYYYMMDD').toString(), // 날짜
          trtTm: moment(new Date().toISOString()).format('HHmmss').toString() // 시간
        }
      }
      window.vue.getStore('progress').dispatch('UPDATE', true)
      piCommonUtil.invoke('UF1MDI0394', inputData, this.fn_ResponsepblSuitaHandler, null, this.fn_ResponsepblSuitaHandler, this, this)
    },
    /************************************************************************************
     * 함수 : fn_ResponsepblSuitaHandler
     * 설명 : P2 요청 처리 Handler
     ************************************************************************************/
    fn_ResponsepblSuitaHandler (result) {
      // 위험성향 발행 결과 설정
      let pISuitaVO = result

      // ChkErrorMsg 를 이용하여 결과 값 알림 팝업 표시 함 - 재활용 하자긔
      this.closeButtonEnabled = true

      if ( pISuitaVO.trnsRsltCd === 'S' ) {
        // this.ChkErrorMsg = '지점 서버로 전송 되었습니다.'
        this.alertMsg = '지점 서버로 전송 되었습니다.'
      } else {
        // this.ChkErrorMsg = pISuitaVO.trnsRsltCntnt
        this.alertMsg = pISuitaVO.trnsRsltCntnt
      }
      this.$refs.refAlert.open()

      // this.isChkErrorMsg = true
      this.STR_suitaReportPblYN = 'Y'
      window.vue.getStore('progress').dispatch('UPDATE', false)
    },
    /********************************************************************************************
     * 함수 : fn_AccordionFocusControl
     * 설명 : 포커스 컨트롤
    ********************************************************************************************/
    fn_AccordionFocusControl(idx) {
      if(idx === '00') {
        if(this.card00.selected) {
          this.card000.detailAreaOpen = true
        }
      }else if(idx === '000') {
        if(this.card000.selected) {
          this.card1.detailAreaOpen = true
        }
      }else {
        let _idx = Number(idx)
        if(_idx === 14) return
        eval('this.card'+_idx+'.selected === true ? this.card'+(_idx+1)+'.detailAreaOpen = true : ""')
      }
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ01
     * 설명 : 전문금융소비자여부 체크 처리 함수
    ********************************************************************************************/
    fn_SetDataQ01 (idx) {
      this.formInput.choice01 = []
      this.formInput.choice01.push(idx.toString())
      if (idx === 2) {
        this.isDisableBtnExprt = false // 전문금융소비자
        this.card00.selectedValue = '전문금융소비자'
        if(this.SuitaExportArray.length > 0) {
          this.card00.selected = true
          this.card00.detailAreaOpen = !this.card00.detailAreaOpen
        }else {
          this.card00.selected = false
        }
      } else {
        this.SuitaExportArray = []
        this.SuitaExportVO.exprtFncCspTypCd = '' // 전문가금융소비자유형코드
        this.SuitaExportVO.genFncCspSameTrtmtYn = ''// 일반금융소비자동일대우여부
        this.isDisableBtnExprt = true // 전문금융소비자
        this.card00.selectedValue = '일반금융소비자'
        this.card00.selected = true
        this.card00.detailAreaOpen = !this.card00.detailAreaOpen
      }
      this.card00Selected = this.card00.selected
      // focus control
      setTimeout(()=>{this.fn_AccordionFocusControl('00')},100)
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ02
     * 설명 : 금융 취약 계층 답변 처리 화면 예외 처리 함수
    ********************************************************************************************/
    fn_SetDataQ02 (idx) {
      if ( this.formInput.choice02.length > 2 ) {
        if ( idx === 1) {
          this.formInput.choice02 = []
          this.formInput.choice02.push(idx.toString())
        } else {
          this.formInput.choice02 = []
          this.formInput.choice02.push(idx.toString())
          this.formInput.choice02.push('4')
        }
      } else if ( this.formInput.choice02.length === 0 ) {
        this.formInput.choice02 = []
        this.formInput.choice02.push(idx.toString())
      } else {
        if ( idx === 1 ) {
          if ( this.formInput.choice02.length !== 1 ) {
            this.formInput.choice02 = []
            this.formInput.choice02.push(idx.toString())
          }
        } else {
          if ( this.formInput.choice02[0] === '1') {
            this.formInput.choice02 = []
            this.formInput.choice02.push(idx.toString())
          } else if ( this.formInput.choice02[0] === '2' || this.formInput.choice02[0] === '3' ) {
            if (idx !== 4) {
              this.formInput.choice02 = []
              this.formInput.choice02.push(idx.toString())
            }
          }
        }
      }
      this.fncCnsm.fncCnsmRstcObjYn = 'N'
      this.fncCnsm.highAgeRskYn = 'N'
      this.fncCnsm.miorYn = 'N'
      this.fncCnsm.icmpYn = 'N'

      // 법정대리인 항목 출력여부 체크 및 데이터 설정
      this.isDisableLeglAgnt = true
      this.card000.selectedValue1 = this.card000.selectedValue2 = '' // 텍스트 초기화
      for ( let i = 0; i < this.formInput.choice02.length; i++ ) {
        if ( this.formInput.choice02[i] === '3' || this.formInput.choice02[i] === '4' ) this.isDisableLeglAgnt = false
        if ( this.formInput.choice02[i] === '1' ) {
          this.fncCnsm.fncCnsmRstcObjYn = 'Y' // 취약금융소비자해당여부
          this.card000.selectedValue1 = '해당없음'
        } else if ( this.formInput.choice02[i] === '2' ) {
          this.fncCnsm.highAgeRskYn = 'Y' // 고연령위험여부
          this.card000.selectedValue1 = '해당(65세 이상)'
        } else if ( this.formInput.choice02[i] === '3' ) {
          this.fncCnsm.miorYn = 'Y' // 미성년자여부
          this.card000.selectedValue1 = '해당(미성년자)'
        } else if ( this.formInput.choice02[i] === '4' ) {
          this.fncCnsm.icmpYn = 'Y' // 기타 금치산자여부
          this.card000.selectedValue2 = '해당(정신적 장애 등)'
        } else {
          console.log('Out of Value')
        }
      }

      if(this.formInput.choice02.length > 1) {
        // 선택된 값이 두개일때
        this.subBtn000 = (this.formInput.choice03.length > 0) ? false : true // 확인버튼 활성/비활성화
      }else if(this.formInput.choice02.length > 0) {
        // 선택된 값이 한개일때
        if(this.card000.selectedValue1 !== '') {
          this.card000.selectedValue2 = ''
        }
        if(this.card000.selectedValue2 !== '') {
          this.card000.selectedValue1 = ''
        }
        let tmp1 = this.formInput.choice02[0]
        if(tmp1 === '1' || tmp1 === '2') {
          this.subBtn000 = false // 확인버튼 활성화
        }else if(tmp1 === '3' || tmp1 === '4') {
          this.subBtn000 = (this.formInput.choice03.length > 0) ? false : true // 확인버튼 활성/비활성화
        }
      }else {}
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ02Confirm
     * 설명 : 금융취약계층 확인 버튼 컨트롤
    ********************************************************************************************/
    fn_SetDataQ02Confirm() {
      this.card000.selected = true
      this.card000Selected = this.card000.selected
      this.card000.detailAreaOpen = false
      setTimeout(()=>{this.fn_AccordionFocusControl('000')},100)
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ02Control
     * 설명 : 금융취약계층 open ui 컨트롤
    ********************************************************************************************/
    fn_SetDataQ02Control() {
      if(this.card000.detailAreaOpen) {
        if(this.formInput.choice02.length < 1) {
          this.card000.detailAreaOpen = !this.card000.detailAreaOpen
        }else if(this.isDisableIns) {
          this.card000.detailAreaOpen = !this.card000.detailAreaOpen
        }
      }else {
        this.card000.detailAreaOpen = !this.card000.detailAreaOpen
      }
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ03
     * 설명 : 법정대리인여부 체크 처리 함수
    ********************************************************************************************/
    fn_SetDataQ03 (idx) {
      this.formInput.choice03 = []
      this.formInput.choice03.push(idx.toString())
      this.fncCnsm.leglAgntYn = this.formInput.choice03.length > 0 ? this.formInput.choice03[0] : '' // 법정대리인여부
      this.subBtn000 = (this.formInput.choice03.length > 0) ? false : true
    },
    /********************************************************************************************
     * 함수 : fn_SetDataAssort
     * 설명 : 전체 데이터 셋팅 공통 함수
    ********************************************************************************************/
    fn_SetDataAssort(idx, val) {
      eval('this.formInput.choice'+idx+' = this.choice'+idx+'Items['+val+'-1]')
      eval('this.card'+idx+'.selected = true')
      eval('this.card'+idx+'Selected = this.card'+idx+'.selected')
      eval('this.card'+idx+'.selectedValue = this.formInput.choice'+idx+'.label')
      eval('this.card'+idx+'.detailAreaOpen = !this.card'+idx+'.detailAreaOpen')
      setTimeout(()=>{this.fn_AccordionFocusControl(idx)},100)
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ3
     * 설명 : 외화보험 가입 후 활용계획 질문 예외 처리 함수
    ********************************************************************************************/
    fn_SetDataQ3 (param) {
      this.card3.selectedValue1 = this.card3.selectedValue2 = this.card3.selectedValue3 = this.card3.selectedValue4 = this.card3.selectedValue5 = this.card3.selectedValue6 = ''
      for (let i = 0; i < this.formInput.choice3.length; i++ ) {
        eval('this.card3.selectedValue'+this.formInput.choice3[i]+' = this.choice3Items['+(Number(this.formInput.choice3[i])-1)+'].label')
      }
      this.formInput.choice3.length > 0 ? this.subBtn3 = false : this.subBtn3 = true
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ3Confirm
     * 설명 : 외화보험 가입 후 활용계획 확인 버튼 컨트롤
    ********************************************************************************************/
    fn_SetDataQ3Confirm() {
      this.card3.selected = this.card3Selected = true
      this.card3.detailAreaOpen = false
      setTimeout(()=>{this.fn_AccordionFocusControl(3)},100)
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ3Control
     * 설명 : 외화보험 가입 후 활용계획 open ui 컨트롤
    ********************************************************************************************/
    fn_SetDataQ3Control() {
      if(this.card3.detailAreaOpen) {
        if(this.formInput.choice3.length < 1) {
          this.card3.detailAreaOpen = !this.card3.detailAreaOpen
        }else if(this.isDisableIns) {
          this.card3.detailAreaOpen = !this.card3.detailAreaOpen
        }else {
          this.fn_SetDataQ3Confirm()
        }
      }else {
        this.card3.detailAreaOpen = !this.card3.detailAreaOpen
      }
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ10
     * 설명 : 외화금융경험상품 질문 예외 처리 함수
    ********************************************************************************************/
    fn_SetDataQ10 (param) {
      this.card10.selectedValue1 = this.card10.selectedValue2 = this.card10.selectedValue3 = this.card10.selectedValue4 = this.card10.selectedValue5 = this.card10.selectedValue6 = this.card10.selectedValue7 = ''
      if ( param === 1 ) {
        this.isDisableInexpr = true
        this.formInput.choice10.splice(0, this.formInput.choice10.length)
        this.formInput.choice10.push('1')
        this.card10.selectedValue1 = this.choice10Items[0].label
        // 11. 외화금융상품 경험 가입기간 셋팅
        this.formInput.choice11 = this.choice11Items[0]
        this.card11.selected = this.card11Selected = true
        this.card11.selectedValue = this.formInput.choice11.label
        this.card11.detailAreaOpen = false
        this.c11 = '1'
      } else {
        let len = this.formInput.choice10.length
        for(let i=len ; i>0 ; i--) {
          eval('this.card10.selectedValue'+this.formInput.choice10[i-1]+' = this.choice10Items['+(Number(this.formInput.choice10[i-1])-1)+'].label')
          // 가입경험 없음 있었으면 지우기
          if(this.formInput.choice10[i-1] === '1') {
            this.formInput.choice10.splice(i-1, 1)
            this.card10.selectedValue1 = ''
          }
        }
        this.isDisableInexpr = false
        if(this.c11 === '1') {
          this.c11 = ''
          this.formInput.choice11 = {key: '0', label: '선택하세요'}
          this.card11.selected = false
          this.card11.selectedValue = ''
        }
      }
      this.formInput.choice10.length > 0 ? this.subBtn10 = false : this.subBtn10 = true
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ10Confirm
     * 설명 : 외화금융경험상품 확인 버튼 컨트롤
    ********************************************************************************************/
    fn_SetDataQ10Confirm() {
      this.card10.selected = this.card10Selected = true
      this.card10.detailAreaOpen = false
      if(this.formInput.choice10.length > 0 && this.formInput.choice10[0] === '1') {
        setTimeout(()=>{this.fn_AccordionFocusControl(11)},100)
      }else {
        setTimeout(()=>{this.fn_AccordionFocusControl(10)},100)
      }
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ10Control
     * 설명 : 외화금융경험상품 open ui 컨트롤
    ********************************************************************************************/
    fn_SetDataQ10Control() {
      if(this.card10.detailAreaOpen) {
        if(this.formInput.choice10.length < 1) {
          this.card10.detailAreaOpen = !this.card10.detailAreaOpen
        }else if(this.isDisableIns) {
          this.card10.detailAreaOpen = !this.card10.detailAreaOpen
        }else {
          this.fn_SetDataQ10Confirm()
        }
      }else {
        this.card10.detailAreaOpen = !this.card10.detailAreaOpen
      }
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ11
     * 설명 : 11번 가입경험없음 선택시 10번 가입경험없음
    ********************************************************************************************/
    fn_SetDataQ11 (val) {
      this.formInput.choice11 = this.choice11Items[val-1]
      this.card11.selected = this.card11Selected = true
      this.card11.selectedValue = this.formInput.choice11.label
      this.card11.detailAreaOpen = !this.card11.detailAreaOpen
      if(val === 1) {
        // 가입경험 없음
        this.isDisableInexpr = true
        // 10. 외화금융경험상품 셋팅
        this.formInput.choice10.splice(0, this.formInput.choice10.length)
        this.formInput.choice10.push('1')
        this.card10.selectedValue1 = this.choice10Items[0].label
        this.card10.selectedValue2 = this.card10.selectedValue3 = this.card10.selectedValue4 = this.card10.selectedValue5 = this.card10.selectedValue6 = this.card10.selectedValue7 = ''
        this.card10.detailAreaOpen = false
        this.subBtn10 = false
      }
      setTimeout(()=>{this.fn_AccordionFocusControl(11)},100)
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ12
     * 설명 : 12번, 13번 항목 답변 선택
    ********************************************************************************************/
    // 위험성향
    fn_SetDataQ12 (val) {
      // 12번, 13번 항목 답변 선택
      // ① 12번 “원금은 반드시 보존되어야 한다“      선택시 13번 “어떠한 경우에도 손실이 나면 안된다”  자동선택 및 다른 답변 비활성화
      // ② 13번 “어떠한 경우에도 손실이 나면 안된다” 선택시 12번 “원금은 반드시 보존되어야 한다“       자동선택 및 다른 답변 비활성화
      this.formInput.choice12 = this.choice12Items[val-1]
      this.card12.selected = this.card12Selected = true
      this.card12.selectedValue = this.formInput.choice12.label
      this.card12.detailAreaOpen = !this.card12.detailAreaOpen
      if(val === 1) {
        // 원금은 반드시 보존
        this.formInput.choice13 = this.choice13Items[0]
        this.card13.selected = this.card13Selected = true
        this.card13.selectedValue = this.formInput.choice13.label
        this.card13.detailAreaOpen = false
        this.c13 = '1'
      }else {
        if(this.c13 === '1') {
          this.formInput.choice13 = {key: '0', label: '선택하세요'}
          this.card13.selected = this.card13Selected = false
          this.card13.selectedValue = ''
          this.c13 = ''
        }
      }
      setTimeout(()=>{this.fn_AccordionFocusControl(12)},100)
    },
    /********************************************************************************************
     * 함수 : fn_SetDataQ12
     * 설명 : 12번, 13번 항목 답변 선택
    ********************************************************************************************/
    // 손실감내수준
    fn_SetDataQ13 (val) {
      // 12번, 13번 항목 답변 선택
      // ① 12번 “원금은 반드시 보존되어야 한다“      선택시 13번 “어떠한 경우에도 손실이 나면 안된다”  자동선택 및 다른 답변 비활성화
      // ② 13번 “어떠한 경우에도 손실이 나면 안된다” 선택시 12번 “원금은 반드시 보존되어야 한다“       자동선택 및 다른 답변 비활성화
      this.formInput.choice13 = this.choice13Items[val-1]
      this.card13.selected = this.card13Selected = true
      this.card13.selectedValue = this.formInput.choice13.label
      this.card13.detailAreaOpen = !this.card13.detailAreaOpen
      if(val === 1) {
        // 어떠한 경우에도 손실이 나면 안된다
        this.formInput.choice12 = this.choice12Items[0]
        this.card12.selected = this.card12Selected = true
        this.card12.selectedValue = this.formInput.choice12.label
        this.card12.detailAreaOpen = false
        this.c12 = '1'
      }else {
        if(this.c12 === '1') {
          this.formInput.choice12 = {key: '0', label: '선택하세요'}
          this.card12.selected = this.card12Selected = false
          this.card12.selectedValue = ''
          this.c12 = ''
        }
      }
      setTimeout(()=>{this.fn_AccordionFocusControl(13)},100)
    },
    /********************************************************************************************
     * 함수 : fn_ClosePopup
     * 설명 :알림 팝업 닫을 때 호출하여 처리하는 함수
    ********************************************************************************************/
    // 그냥 알림 팝업 닫을 때 호출 하면 됨
    fn_ClosePopup () {
      if(this.fn_CheckItems()) {
        let alertMsg = '작성중인 화면에서 나가시겠습니까? 나가기 선택 시 작성된 내용은 저장되지 않습니다.'
        let alertObj = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: alertMsg,
            title_pos_btn: '나가기'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(alertObj)
              this.$options.isBackKeyHandle = false
              this.$emit('onClose')
            },
            onPopupClose: () => {
              this.$bottomModal.close(alertObj)
            }
          }
        })
      }else {
        this.$options.isBackKeyHandle = false
        this.$emit('onClose')
      }
    },
    /********************************************************************************************
     * 함수 : fn_ConfirmAuthCheck
     * 설명 : '고객 선택 후 진행하시기 바랍니다' 팝업 처리
    ********************************************************************************************/
    fn_ConfirmAuthCheck () {
      this.isChkErrorMsg = false
      this.isCustCheck = false
      this.isRskEftAnlyAlert = false
    },
    /********************************************************************************************
     * 함수 : fn_suitaEditConfirmPositive
     * 설명 : 버튼 활성화 처리
    ********************************************************************************************/
    fn_suitaEditConfirmPositive () {
      if (this.fn_checkInputAnswer()) {
        return false
      }
      this.isSuitaEditConfirm = false // 적합성 입력완료 버튼 활성
      // 발행 버튼 활성화 시킴
      this.isDisableBtnPlb = false
      // EditConfirmMsg: '영업관리자 ‘질문지 승인’ 이후에 상품설명서(제안용) 발행이 가능합니다',
      this.isEditConfirm = true
      this.alertMsg = this.EditConfirmMsg
      this.$refs.refAlert.open()
    },
    /********************************************************************************************
     * 함수 : fn_suitaEditConfirmNegative
     * 설명 : 적합성 진단 이력이 있는 고객입니다. 취소 버튼 선택 시 처리 함수
    ********************************************************************************************/
    fn_suitaEditConfirmNegative () {
      this.isSuitaEditConfirm = false
    },
    /********************************************************************************************
     * 함수 : fn_rskEftAnlyResPositive
     * 설명 : 위험성향진단 예약발행 팝업 - 확인 선택
    ********************************************************************************************/
    fn_rskEftAnlyResPositive () {
      this.isRskEftAnlyRes = false

      // TODO: P2 Request 처리 하는 곳 - getServiceData("P2");
      // 위험성향진단 예약발행 ( P2 ) - 요청
      this.fn_RequestpblSuita()
    },
    /********************************************************************************************
     * 함수 : fn_rskEftAnlyResNegative
     * 설명 : 위험성향진단 예약발행 팝업 - 취소 선택
    ********************************************************************************************/
    fn_rskEftAnlyResNegative () {
      this.isRskEftAnlyRes = false
    },
    /********************************************************************************************
     * 함수 : fn_EditConfirm
     * 설명 : 재 승인 처리를 위한 적합성 진단 다시 수행
    ********************************************************************************************/
    fn_EditConfirm () {
      this.aprvYn = 'N' // 재 승인 처리로 인해 승인여부 'N'으로 설정
      this.isEditConfirm = false

      window.vue.getStore('progress').dispatch('UPDATE', true)
      // 입력한 응답에 대한 데이터 처리 ( 요청도 해야되고 할게 많음 )
      this.fn_setSuitaAnswerData()
      // S2 요청 처리
      this.fn_RequestSuitaDign()

      // 스크롤 최상단
      this.$refs.page.scrollTo(0)
      // 카드 아코디언 close
      this.card0.detailAreaOpen = true
      this.card00.detailAreaOpen = this.card000.detailAreaOpen = false
      this.card1.detailAreaOpen = this.card2.detailAreaOpen = this.card3.detailAreaOpen = this.card4.detailAreaOpen = this.card5.detailAreaOpen = false
      this.card6.detailAreaOpen = this.card7.detailAreaOpen = this.card8.detailAreaOpen = this.card9.detailAreaOpen = this.card10.detailAreaOpen = false
      this.card11.detailAreaOpen = this.card12.detailAreaOpen = this.card13.detailAreaOpen = this.card14.detailAreaOpen = false
      // 하단 버튼 컨트롤 - 진단결과 발행 / 디세이블
      this.isShowBtnCmplt = true // 선택완료, 진단결과 발행 버튼 show
      this.cmpltBtnTxt = '진단결과 발행'
      this.btnStatus1 = '2' // 1-선택완료, 2-진단결과발행
      this.isDisableBtnCmplt = true // 진단결과 발행 버튼 비활성화
      this.isShowBtnOldDataUse = false // 기존정보 사용 버튼
      this.isShowBtnRetry = false // 다시진단하기, 변경 재진단 버튼
    },
    /********************************************************************************************
     * 함수 : fn_EditConfirm
     * 설명 : 고객 정보를 찾을 수 없습니다. 팝업 닫기
    ********************************************************************************************/
    fn_NotFndGstFlg () {
      this.isNotFndGstFlg = false
    },
    /******************************************************************************************
     * 함수 : fn_PrePopupBtnConfirmHandler
     * 설명 : 첫화면 진입 후 적합성 진단 결과 유무 체크하기 위한 함수
     ******************************************************************************************/
    fn_PrePopupBtnConfirmHandler () {
      this.$refs.refDefaultModal.close()  // 팝업 닫기
      this.fn_RequestSuitaQstnInve()
    },
    /******************************************************************************************
     * 함수 : fn_showTSSPI721P
     * 설명 : TSSPI721P 보이기 - 전문금융소비자 팝업
     ******************************************************************************************/
    fn_showTSSPI721P () {
      this.isTSSPI721P = true
      // 전문금융소비자 입력용 팝업 오픈
      let lv_Vm = this
      lv_Vm.msppi452p = lv_Vm.$bottomModal.open(MSPPI452P, {
        properties: {
          pParam: lv_Vm.SuitaExportArray
        },
        listeners: {
          ExportConfirmData: (pData) => {
            lv_Vm.$bottomModal.close(lv_Vm.msppi452p)
            lv_Vm.fn_ConfirmTSSPI721P(pData)
          },
          ExportCancelData: (pData) => {
            lv_Vm.$bottomModal.close(lv_Vm.msppi452p)
          }
        }
      },
      {
        properties: {noHeader: true}
      })
    },
    /******************************************************************************************
     * 함수 : fn_closeBmngrMsg
     * 설명 : '지점장에게 승인요청 하시기 바랍니다.' 닫기
     ******************************************************************************************/
    fn_closeBmngrMsg () {
      this.isBmngrMsg = false
    },
    /******************************************************************************************
     * 함수 : fn_ConfirmTSSPI721P
     * 설명 :  TSSPI721P 닫고, 전달값 설정 - 전문금융소비자 설정값 전달 받아 처리
     ******************************************************************************************/
    fn_ConfirmTSSPI721P (param) {
      if ( param === false) return

      this.isTSSPI721P = false
      this.isDisableBtnPlb = true
      this.SuitaExportArray = param
      this.SuitaExportVO.exprtFncCspTypCd = '' // 전문가금융소비자유형코드
      this.SuitaExportVO.genFncCspSameTrtmtYn = ''// 일반금융소비자동일대우여부

      for ( let i = 0; i < this.SuitaExportArray.length; i++ ) {
        if ( this.SuitaExportArray[i] === '1' ) {
          this.SuitaExportVO.exprtFncCspTypCd = '1' // 전문가금융소비자유형코드: 국가/지자체/공공기관 및 법인/단체 등
        } else if ( this.SuitaExportArray[i] === '2' ) {
          this.SuitaExportVO.exprtFncCspTypCd = '2' // 전문가금융소비자유형코드: 보험설계사대리점 및 보험중개사 등
        } else if ( this.SuitaExportArray[i] === '9' ) {
          this.SuitaExportVO.exprtFncCspTypCd = '9' // 전문가금융소비자유형코드: 기타
        } else if ( this.SuitaExportArray[i] === '4' ) {
          this.SuitaExportVO.genFncCspSameTrtmtYn = 'Y'// 일반금융소비자동일대우여부
        } else {
          console.log('Error!!! Condition')
        }
      }

      if(this.SuitaExportArray.length > 0) {
        this.card00.selected = true
        this.card00Selected = this.card00.selected
        this.card00.detailAreaOpen = !this.card00.detailAreaOpen
        setTimeout(()=>{this.fn_AccordionFocusControl('00')},100)
      }
    },
    /******************************************************************************************
     * 함수 : fn_clearn
     * 설명 : 화면 선택 답변 정보 초기화 처리
     ******************************************************************************************/
    fn_clearn () {
      this.formInput = {
        choice01: [],
        choice02: [],
        choice03: [],
        choice1: { key: '0', label: '선택하세요' },
        choice2: { key: '0', label: '선택하세요' },
        choice3: [],
        choice4: { key: '0', label: '선택하세요' },
        choice5: { key: '0', label: '선택하세요' },
        choice6: { key: '0', label: '선택하세요' },
        choice7: { key: '0', label: '선택하세요' },
        choice8: { key: '0', label: '선택하세요' },
        choice9: { key: '0', label: '선택하세요' },
        choice10: [],
        choice11: { key: '0', label: '선택하세요' },
        choice12: { key: '0', label: '선택하세요' },
        choice13: { key: '0', label: '선택하세요' },
        choice14: { key: '0', label: '선택하세요' }
      }
      this.c0 = this.c00 = this.c000 = ''
      this.c1 = this.c2 = this.c4 = this.c5 = this.c6 = this.c7 = this.c8 = this.c9 = this.c11 = this.c12 = this.c13 = this.c14 = ''
      this.card0 = {selected: false, selectedValue:'', detailAreaOpen: false, result: false}
      this.card00 = {selected: false, selectedValue:'', detailAreaOpen: this.card00.detailAreaOpen ? true : false}
      this.card000 = {selected: false, selectedValue1:'',selectedValue2:'', detailAreaOpen: false}
      this.card1 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card2 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card3 = {
        selected: false,
        selectedValue1:'', selectedValue2:'', selectedValue3:'', selectedValue4:'', selectedValue5:'', selectedValue6:'',
        detailAreaOpen: false
      }
      this.card4 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card5 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card6 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card7 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card8 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card9 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card10 = {
        selected: false,
        selectedValue1:'', selectedValue2:'', selectedValue3:'', selectedValue4:'', selectedValue5:'', selectedValue6:'', selectedValue7:'',
        detailAreaOpen: false
      }
      this.card11 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card12 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card13 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card14 = {selected: false, selectedValue:'', detailAreaOpen: false}
      this.card0Selected = this.card00Selected = this.card000Selected = false
      this.card1Selected = this.card2Selected = this.card3Selected = this.card4Selected = this.card5Selected = false
      this.card6Selected = this.card7Selected = this.card8Selected = this.card9Selected = this.card10Selected = false
      this.card11Selected = this.card12Selected = this.card13Selected = this.card14Selected = false
      this.rDignYn = '' // 재진단여부
      this.rDignRsnCd = '' // 재진단사유코드
    },
    /******************************************************************************************
     * 함수 : fn_ConfirmTSSPI723P
     * 설명 :  TSSPI723P 적합성재진단 팝업 - 팝업창 '확인' 버튼 클릭 시 호출
     ******************************************************************************************/
    fn_ConfirmTSSPI723P () {
      this.isTSSPI723P = false
      this.isTSSPI723PConfirm = true
      this.$refs.refDignRsnAlert.close() // 바텀시트 닫기

      this.rDignYn = 'Y'
      if ( this.rDignRsnCd !== '' ) {
        // 초기화
        // this.fn_clearn()
        // this.isDisableIns = false
        // this.isDisableInexpr = false
        // this.isReSuita = false
        // this.card00.detailAreaOpen = true
        this.isShowBtnCmplt = false
        this.isShowBtnOldDataUse = this.isShowBtnRetry = true
        this.isDisableBtnCmplt = false
      }
      // 당일 재진단이 아닐경우 질문지 항목 15~20번도 수정할 수 있게 변경한다.
      let _ZA_APL_DTM = this.dcSuitaCnsntVO.za_APL_DTM === undefined || (String(this.dcSuitaCnsntVO.za_APL_DTM).trim() === '' ) ? '' : this.dcSuitaCnsntVO.za_APL_DTM.substr(0, 8)
      if(_ZA_APL_DTM !== '' && _ZA_APL_DTM !== piCommonUtil.getCurrentDate()) {
        this.isReSuita = false
      }
    },
    /******************************************************************************
    * Function명 : fn_CancelTSSPI723P
    * 설명       : 재진단 사유 팝업  - 팝업창(X) 닫는 이벤트 emit
    ******************************************************************************/
    fn_CancelTSSPI723P (param) {
      this.$refs.refDignRsnAlert.close() // 바텀시트 닫기
      this.rDignRsnCd = ''
      if(this.btnStatus1 === '1') {
        // 선택완료
        this.isDisableBtnCmplt = true // 비활성화
      }else if(this.btnStatus1 === '2') {
        // 진단결과 발행
        this.isDisableBtnCmplt = false // 활성화
      }
    },
    
    _fn_End(){return} // 코딩 종료 함수
  },
  props: {'pParams': null}, // 부모창에서 오는 Param
  data () {
    return {
      lv_HeaderviewScrollCompID: null,
      displayCustNm: '',
      isComplateGENSuita: false,
      isGENFlg: false,
      isGENMsg: '계약자가 법인인 경우 대표가 적합성진단을 하셔야 합니다.',
      isTSSPI310P: false,
      msppi404p: {}, // MSPPI404P 법인고객조회 팝업
      // showDefaultModal: true,
      showPopup: true,
      inputDone: true,
      // 여기는 GAF 친구들 [
      rltnrInfo: [], // Gaf에서 받은 고객(주피 정보)
      // 여기는 GAF 친구들 ]
      // 사랑온에서 가져온 변수 [
      closeButtonEnabled: true,
      STR_suitaReportPblYN: 'Y',
      aprvYn: '', // 승인여부
      aprvMsg: '', // 승인 여부 메시지
      suitaDignId: '', // 적합성진단ID
      MB_entPpsQstnRslt: '', // 가입목적설문결과
      MB_entDalwObjJdgmt: 'N', // 가입 불가 대상판
      strMB_prextSuitaYn: 'N', // 적합성 수행 이력이 있는 경우 Y, 아니면 N
      lbRskEftAnlyRslt: '', // 위험영향분석결과에 따른 메세지 [ 특별계정펀드 적합성 ]
      lbRskEftAnlyRslt_Date: '', // [ 특별계정펀드 적합성 ] 만료 날짜
      lbInsrAnlyRslt: '', // 보험상품적합성 결과 메세지
      lbInsrAnlyRslt_Date: '', // [ 보험상품적합성 ] 만료 날짜
      // entDalwObjJdgmtYn: '', // 가입불가대상판단여부
      rskEftAnlyRslt: '', // 위험성향분석결과코드
      // 사랑온에서 가져온 변수 ]
      headerVO: '', // 사용자 정보 저장 변수
      custInfo: {
        custNm: '', // 가입자 이름
        age: '', // 가입자 나이
        knb: '', // 가입자 주민 번호
        celno: '', // 가입자 전화번호
        emailAddr: '', // 가입자 이메일 주소
        cnsltNo: '', // 사용자 ID
        chnlCustId: '', // 채널 고객 ID
        zzcusDtlTypCd: '', // 고객상세유형코드
        contrChnlCustId: '', // 계약자 채널 고객 ID
        lbContrRrn: '' // 가입자 주민 번호
      },
      fncCnsm: { // 금융 취약 계층 답변
        fncCnsmRstcObjYn: '', // 취약금융소비자해당여부
        highAgeRskYn: '', // 고연령위험여부
        icmpYn: '' // 금치산자여부
      },
      ivtExpr: {
        ivtExprStckMngQstnRslt: '', // 투자경험주식관리설문결과
        ivtExprStckGenQstnRslt: '', // 투자경험주식일반설문결과
        ivtExprBlendQstnRslt: '', // 투자경험혼합설문결과
        ivtExprBtypQstnRslt: '', // 투자경험채권형설문결과
        ivtExprVainsQstnRslt: '', // 투자경험변액설문결과
        ivtExprNhvQstnRslt: '' // 투자경험무설문결과
      },
      qstnExpr: { // 12. 투자경험 목적 설문결과
        qstnIvtNhvExprYn: '', // 12. 위험성향설문결과: 설문투자무경험여부
        qstnExprSprdFrtunYn: '', // 12. 위험성향설문결과: 설문경험단기재산여부
        qstnExprLtmSavYn: '', // 12. 위험성향설문결과: 설문경험장기저축여부
        qstnExprAnutAsetYn: '', // 12. 위험성향설문결과: 설문경험연금자산여부
        qstnExprCovrAsetYn: '' // 12. 위험성향설문결과: 설문경험보장자산여부
      },
      PRCUS_PLN: { // 외화_3.활용계획
        ZA_PRCUS_PLN_OVERS_DW_YN: '', // 1 활용계획해외거주여부
        ZA_PRCUS_PLN_OVERS_TRV_YN: '', // 2 활용계획해외여행여부
        ZA_PRCUS_PLN_LVNG_FD_YN: '', // 3 활용계획생활자금여부
        ZA_PRCUS_PLN_IHRT_YN: '', // 4 활용계획상속여부
        ZA_PRCUS_PLN_IVT_YN: '', // 5 활용계획투자여부
        ZA_PRCUS_PLN_COVR_ASET_YN: '' // 6 활용계획보장자산여부
      },
      QSTN_EXPR: { // 외화_10. 금융상품 가입 경험
        ZA_QSTN_EXPR_FCURR_YN: '', // 1 가입경험 없음
        ZA_QSTN_EXPR_FCURR_DPST_YN: '', // 2 외화예금
        ZA_QSTN_EXPR_FCURR_FND_YN: '', // 3 외화펀드
        ZA_QSTN_EXPR_FCURR_STCK_YN: '', // 4 해외주식
        ZA_QSTN_EXPR_FCURR_SAV_YN: '', // 5 외화저축성보험
        ZA_QSTN_EXPR_FCURR_COVR_YN: '', // 6 외화보장성보험
        ZA_QSTN_EXPR_FCURR_ETC_YN: '' // 7 기타
      },
      dcSuitaCnsntVO: null, // 적합성 입력 완료 후 적합성 심사 시 서버에 요청할 VO
      SuitaExportArray: [],
      SuitaExportVO: {
        exprtFncCspYn: 'N', // 전문가금융소비자여부
        exprtFncCspTypCd: '', // 전문가금융소비자유형코드
        genFncCspSameTrtmtYn: 'N'// 일반금융소비자동일대우여부
      },
      isDisableIns: true, // 응답 입력 비활성화 처리
      isDisableLeglAgnt: true, // 법정대리인여부 비활성화 처리
      // isDisableBtnDnl: false, // 적합성 진단 거부 버튼 비활성 처리
      isDisableBtnExprt: true, // 전문금융소비자
      isDisableBtnCmplt: true, // 적합성 입력 완료 버튼 비활성 처리 // true -> false (22.01.18_PJO)
      isDisableBtnPlb: true, // 발행 버튼 비활성 처리
      //----------------------------------
      isShowBtnCmplt: true,
      cmpltBtnTxt: '선택완료',
      btnStatus1: '1', // 1-선택완료, 2-적합성 발행
      isShowBtnOldDataUse: false,
      isShowBtnRetry: false,
      //----------------------------------
      isDisableInexpr: false, // 투자경험 없음
      isTSSPI721P: false, // 전문금융소비자 팝업
      isTSSPI723P: false, // 적합성 재진단 팝업
      isReSuita: false, // 적합성 재진단 유무
      rDignYn: '', // 재진단여부
      rDignRsnCd: '', // 재진단사유코드
      isUnAprv: false, // 진단 미승인 메시지 알림
      STR_suitaReportYN: 'N', // 적합성 보고서 발행 대상. 초기값로는 가설 메인에서 값을 전달 해줌
      isCustCheck: false, // 고객 정보 유무 체크 후 팝업 표시
      isSuitaEditConfirm: false, // 승인 여부 체크 - 현재 적합성이 적합성 심사 받은 경우 true
      ErrorMsg1: '고객 선택 후 진행하시기 바랍니다',
      ErrorMsg2: '적합성 진단 이력이 있는 고객입니다.\n 새롭게 질문지를 받아오시고, 질문지 내용에\n 수정이 있는 건입니까?',
      ErrorMsg3: '승인되지 않은 질문지가 있습니다.\n질문지 승인 후 재입력 가능합니다.',
      isRskEftAnlyRes: false,
      RskEftAnlyResMsg: '위험성향진단 예약발행 \n\n 인쇄를 하시겠습니까?',
      isRskEftAnlyAlert: false,
      RskEftAnlyAlertMsg: '위험성향 진단 질문지 승인후 발행가능합니다.(진단일 + 3개월)',
      isChkErrorMsg: false,
      ChkErrorMsg: '',
      isEditConfirm: false,
      // isPrpnsDignDnl: false, // 적합성 이력이 있는 경우, 적합성 진단 거부 시 알림 팝업 표시 flag
      isNotFndGstFlg: false,
      isNotFndGstMsg: '고객 정보를 찾을 수 없습니다.',
      isBmngrMsg: false,
      EditConfirmMsg: '영업관리자 ‘질문지 승인’ 이후에 상품설명서(제안용) 발행이 가능합니다.',
      isSuitaExpire: false, // 적합성 심사 결과 3개월 초과 여부 확인 변수 - 팝업창 띄우기용
      ChkExpirMsg: '적합성진단 유효기간(91일)이 만료되어 사용하실 수 없습니다. 적합성진단을 재수행 바랍니다.', // 3개월->91일 (2021.11_PJO)
      prpnsDignDnlAlertMsg: '부적절한 방법으로 위험성향진단 거부를\n유도했을 경우 보험업법 및 자본시장법 위반입니다.\n(지점장에게 승인요청 하시기 바랍니다)', // 적합성 이력이 있는 경우, 적합성 진단 거부 시 알림 팝업
      reqConfirmFromBmngrMsg: '지점장에게 승인요청 하시기 바랍니다.',
      alertPopupSuitaMsg: '재진단사유가 입력되지 않았습니다.\n\n재진단 사유 입력 하시겠습니까?', // (20220118_PJO)
      alertPopupSuitaFlag: false,
      isTSSPI723PConfirm: false, // 적합성 재진단 팝업 '확인' 버튼 클릭시 true변경됨
      ErrorMsgs: {
        label01: '전문금융소비자여부가 선택되지 않았습니다.',
        label012: '하단에 있는 전문금융소비자 버튼을 클릭하여 전문금융소비자 정보를 입력해주세요.',
        label02: '금융취약계층의 항목중 하나이상 선택해주십시오.',
        label03: '법정대리인여부가 선택되지 않았습니다.',
        label1: '1.가입희망상품이 선택되지 않았습니다.',
        label2: '2.외화보험 가입목적이 선택되지 않았습니다.',
        label3: '3.활용계획 항목중 하나이상 선택해주십시오.',
        label4: '4.월평균소득이 선택되지 않았습니다.',
        label5: '5.보험료 비중이 선택되지 않았습니다.',
        label6: '6.추가납부금액이 선택되지 않았습니다.',
        label7: '7.납입가능기간이 선택되지 않았습니다.',
        label8: '8.예상 유지기간이 선택되지 않았습니다.',
        label9: '9.중도해지 가능성이 선택되지 않았습니다.',
        label10: '10.외화상품 투자경험 항목중 하나이상 선택해주십시오.',
        label11: '11.외화상품 가입기간이 선택해주십시오.', // 투자경험 목적의 항목중 하나이상 선택해주십시오.
        label12: '12.위험성향이 선택되지 않았습니다.',
        label13: '13.손실감내수준이 선택되지 않았습니다.',
        label14: '14.외화상품 이해수준이 선택되지 않았습니다.'
      },
      // 화면에서 사용, 화면 처리 변수
      formInput: {
        choice01: [],
        choice02: [],
        choice03: [],
        choice1: { key: '0', label: '선택하세요' },
        choice2: { key: '0', label: '선택하세요' },
        choice3: [],
        choice4: { key: '0', label: '선택하세요' },
        choice5: { key: '0', label: '선택하세요' },
        choice6: { key: '0', label: '선택하세요' },
        choice7: { key: '0', label: '선택하세요' },
        choice8: { key: '0', label: '선택하세요' },
        choice9: { key: '0', label: '선택하세요' },
        choice10: [],
        choice11: { key: '0', label: '선택하세요' },
        choice12: { key: '0', label: '선택하세요' },
        choice13: { key: '0', label: '선택하세요' },
        choice14: { key: '0', label: '선택하세요' }
      },
      //-----------------------------------------------------------------------------------------------------------------
      c0: '',c00: '',c000: '',
      c1: '',c2: '',c4: '',c5: '',c6: '',c7: '',c8: '',c9: '',c11: '',c12: '',c13: '',c14: '',
      card0: {selected: false, selectedValue:'', detailAreaOpen: false, result: false},
      card00: {selected: false, selectedValue:'', detailAreaOpen: false},
      card000: {selected: false, selectedValue1:'',selectedValue2:'', detailAreaOpen: false},
      card1: {selected: false, selectedValue:'', detailAreaOpen: false},
      card2: {selected: false, selectedValue:'', detailAreaOpen: false},
      card3: {
        selected: false,
        selectedValue1:'', selectedValue2:'', selectedValue3:'', selectedValue4:'', selectedValue5:'', selectedValue6:'',
        detailAreaOpen: false
      },
      card4: {selected: false, selectedValue:'', detailAreaOpen: false},
      card5: {selected: false, selectedValue:'', detailAreaOpen: false},
      card6: {selected: false, selectedValue:'', detailAreaOpen: false},
      card7: {selected: false, selectedValue:'', detailAreaOpen: false},
      card8: {selected: false, selectedValue:'', detailAreaOpen: false},
      card9: {selected: false, selectedValue:'', detailAreaOpen: false},
      card10: {
        selected: false,
        selectedValue1:'', selectedValue2:'', selectedValue3:'', selectedValue4:'', selectedValue5:'', selectedValue6:'', selectedValue7:'',
        detailAreaOpen: false
      },
      card11: {selected: false, selectedValue:'', detailAreaOpen: false},
      card12: {selected: false, selectedValue:'', detailAreaOpen: false},
      card13: {selected: false, selectedValue:'', detailAreaOpen: false},
      card14: {selected: false, selectedValue:'', detailAreaOpen: false},
      card0Selected: false, card00Selected: false, card000Selected: false,
      card1Selected: false, card2Selected: false, card3Selected: false, card4Selected: false, card5Selected: false,
      card6Selected: false, card7Selected: false, card8Selected: false, card9Selected: false, card10Selected: false,
      card11Selected: false, card12Selected: false, card13Selected: false, card14Selected: false,
      subBtn000: true, subBtn3: true, subBtn10: true, // 확인 버튼 disabled
      mspcm129P: {}, // MSPCM129P 고객조회 팝업
      msppi404p: {}, // MSPPI404P 고객조회 팝업
      msppi452p: {}, // MSPPI452P 전문금융소비자 입력용 팝업
      isDisableCust: true, // 고객명 입력필드 활성/비활성 flag
      alertMsg: '', // alert 메시지
      alertBtnLb: '확인',
      alertBtnLb1: '',
      alertBtnLb2: '',
      searchable: true, // 고객검색버튼 show/hide
      oldDataUseFlag: false,
      //-----------------------------------------------------------------------------------------------------------------
      // 1.가입목적 금융상품
      choice1Items: [
        {key: '1', label: '예금·적금'},
        {key: '2', label: '주식·채권 등 금융투자상품'},
        {key: '3', label: '일반보험'},
        {key: '4', label: '변액보험'},
        {key: '5', label: '외화 일반보험(비변액)'},
        {key: '6', label: '외화 변액보험'}
      ],
      // 2.외화보험 가입목적
      choice2Items: [
        {key: '1', label: '환율변동에 따른 재산증식 등 단기적인 이익 취득 목적'},
        {key: '2', label: '장기 저축을 통한 목돈 마련'},
        {key: '3', label: '노후를 위한 연금자산 마련'},
        {key: '4', label: '위험에 대비한 보장자산(외화) 마련'}
      ],
      // 3.외화보험 가입 후 활용계획(복수선택 가능)
      choice3Items: [
        {key: '1', label: '향후 해외 거주할 예정이며, 준비 또는 생활자금 등으로 활용'},
        {key: '2', label: '가끔 해외여행경비로 활용'},
        {key: '3', label: '장기의 미래에 본인 생활자금 또는 유학계획이 있는 자녀 생활자금등으로 활용'},
        {key: '4', label: '장기의 미래에 유족 등에게 외화자산 상속 등 보장자산으로 활용'},
        {key: '5', label: '안정적 재산증식 수단으로 손실가능성이 없는 외화를 활용'},
        {key: '6', label: '외화 보장자산 확보를 통한 보장자산 다각화 등 미래 위험분산에 활용'}
      ],
      // 4.월평균소득
      choice4Items: [
        {key: '1', label: '200만원 미만'},
        {key: '2', label: '500만원 미만'},
        {key: '3', label: '1,000만원 미만'},
        {key: '4', label: '1,000만원 이상'}
      ],
      // 5.보험료 비중
      choice5Items: [
        {key: '1', label: '10% 미만'},
        {key: '2', label: '20% 미만'},
        {key: '3', label: '30% 미만'},
        {key: '4', label: '50% 미만'},
        {key: '5', label: '50% 이상'}
      ],
      // 6.추가납부금액
      choice6Items: [
        {key: '1', label: '10만원 이하'},
        {key: '2', label: '30만원 미만'},
        {key: '3', label: '50만원 미만'},
        {key: '4', label: '100만원 미만'},
        {key: '5', label: '100만원 이상'}
      ],
      // 7.납입가능기간
      choice7Items: [
        {key: '1', label: '3년 미만'},
        {key: '2', label: '3년 이상 7년 미만'},
        {key: '3', label: '7년 이상 10년 미만'},
        {key: '4', label: '10년 이상 20년 미만'},
        {key: '5', label: '20년 이상'}
      ],
      // 8.예상 유지기간
      choice8Items: [
        {key: '1', label: '5년 미만'},
        {key: '2', label: '5년 이상 7년 미만'},
        {key: '3', label: '7년 이상 10년 미만'},
        {key: '4', label: '10년 이상 20년 미만'},
        {key: '5', label: '20년 이상 30년 미만'},
        {key: '6', label: '30년 이상'}
      ],
      // 9.중도해지 가능성
      choice9Items: [
        {key: '1', label: '매우 낮음(30%미만)'},
        {key: '2', label: '낮음(30% 이상)'},
        {key: '3', label: '가능성 있음(50% 이상)'},
        {key: '4', label: '높음(70% 이상)'},
        {key: '5', label: '매우 높음(90% 이상)'}
      ],
      // 10.외화금융경험상품(복수선택가능)
      choice10Items: [
        {key: '1', label: '가입경험 없음'},
        {key: '2', label: '외화예금'},
        {key: '3', label: '외화펀드'},
        {key: '4', label: '해외주식'},
        {key: '5', label: '외화저축성보험'},
        {key: '6', label: '외화보장성보험'},
        {key: '7', label: '기타'}
      ],
      // 11.외화상품가입기간
      choice11Items: [
        {key: '1', label: '가입경험 없음'},
        {key: '2', label: '6개월 미만'},
        {key: '3', label: '1년 미만'},
        {key: '4', label: '2년 미만'},
        {key: '5', label: '2년 이상'}
      ],
      // 12.위험성향
      choice12Items: [
        {key: '1', label: '원금은 반드시 보존되어야 한다'},
        {key: '2', label: '원금보전을 중요하게 생각한다'},
        {key: '3', label: '수익률이 어느정도 되어야 한다'},
        {key: '4', label: '수익률을 중요시 한다'}
      ],
      // 13.손실감내수준
      choice13Items: [
        {key: '1', label: '어떠한 경우에도 손실이 나면 안된다'},
        {key: '2', label: '원금 기준 10% 이내'},
        {key: '3', label: '원금 기준 20% 이내'},
        {key: '4', label: '원금 기준 30% 이내'},
        {key: '5', label: '원금 기준 40% 이내'},
        {key: '6', label: '원금 기준 50% 이내'},
        {key: '7', label: '원금 기준 100% 수준도 감수할 수 있다'}
      ],
      // 14.외화상품 이해수준
      choice14Items: [
        {key: '1', label: '이해수준이 현전히 낮음'},
        {key: '2', label: '외화보험 등 금융상품의 구조와 위험에 대해 일정 부분 이해'},
        {key: '3', label: '외화보험 등 금융상품의 구조와 위험에 대해 전체적으로 잘 이해'}
      ]
    }
  }
}
</script>